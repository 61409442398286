import { Grid, Image } from 'semantic-ui-react'

import {
  Accordion,
  Card,
  GridRowColumn,
  Text,
} from '../../components/BaseComponents'
import ReferralEmailForm from './ReferralEmailForm'

import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'

const Referrals = () => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <Grid>
      <GridRowColumn spacer={isMobile ? 1 : undefined}>
        <Text as="h1">Rewards</Text>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column computer={11} tablet={11} mobile={16}>
          <Card>
            <Grid>
              <GridRowColumn>
                <Text as="h2">How it works</Text>
              </GridRowColumn>
              <Grid.Row verticalAlign="middle" className="short">
                <Grid.Column width={3} only="computer tablet">
                  <Image
                    style={{ height: 100, width: 90, maxWidth: 'initial' }}
                    src="https://heard-images.s3.amazonaws.com/assets/gift.svg"
                  />
                </Grid.Column>
                <Grid.Column computer={13} tablet={13} mobile={16}>
                  <Text as="bodyLg">
                    <ol>
                      <li>Invite your friend(s) via email below.</li>
                      <li>
                        Your friend signs up for Heard&apos;s 30-day free trial
                        from the invite email.
                      </li>
                      <li>
                        You&apos;ll receive a $
                        {
                          process.env
                            .VITE_REFERRAL_FACTORY_REWARD_DOLLARS_SENDER_FIRST
                        }{' '}
                        gift card when your friend completes their 30-day free
                        trial, and then you&apos;ll receive another $
                        {
                          process.env
                            .VITE_REFERRAL_FACTORY_REWARD_DOLLARS_SENDER_SECOND
                        }{' '}
                        gift card once your friend stays an active member of
                        Heard 2 months after their free trial ends.
                      </li>
                    </ol>
                  </Text>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card>
          <Card>
            <Grid>
              <GridRowColumn>
                <Text as="h2">Refer a friend</Text>
              </GridRowColumn>
              <GridRowColumn>
                <ReferralEmailForm />
              </GridRowColumn>
              <Grid.Row />
            </Grid>
          </Card>
        </Grid.Column>
        <Grid.Column width={5} only="computer tablet">
          <Card>
            <Grid>
              <GridRowColumn>
                <Text as="h2">FAQs</Text>
              </GridRowColumn>
              <GridRowColumn>
                <Accordion
                  title="Is there a limit to how many people I can refer?"
                  content={
                    <Text>
                      Nope! We&apos;re ready to grow and support therapists
                      across the country so refer as many friends as you&apos;d
                      like.
                    </Text>
                  }
                  variant="text"
                />
              </GridRowColumn>
              <GridRowColumn>
                <Accordion
                  title="When do I get my rewards?"
                  content={
                    <Text>
                      You&apos;ll receive your $
                      {
                        process.env
                          .VITE_REFERRAL_FACTORY_REWARD_DOLLARS_SENDER_FIRST
                      }{' '}
                      gift card once your friend complete their 30-day free
                      trial, and then you&apos;ll receive another $
                      {
                        process.env
                          .VITE_REFERRAL_FACTORY_REWARD_DOLLARS_SENDER_SECOND
                      }{' '}
                      gift card once your friend stays an active member of Heard
                      2 months after their free trial ends.
                    </Text>
                  }
                  variant="text"
                />
              </GridRowColumn>
              <GridRowColumn>
                <Accordion
                  title="How do I redeem my rewards?"
                  content={
                    <Text>
                      You&apos;ll receive an email with instructions and a link
                      to your gift card.
                    </Text>
                  }
                  variant="text"
                />
              </GridRowColumn>
              <GridRowColumn>
                <Accordion
                  title="Why didn't I receive my reward?"
                  content={
                    <>
                      <div>
                        <Text>
                          There could be several reasons why you haven&apos;t
                          received your rewards:
                        </Text>
                      </div>
                      <ul>
                        <li>
                          <Text>
                            If you haven&apos;t received your first $200
                            payment, your friend hasn&apos;t signed up or been a
                            Heard member for 30 days
                          </Text>
                        </li>
                        <li>
                          <Text>
                            If you haven&apos;t received your second $200
                            payment, your friend hasn&apos;t been a Heard member
                            for at least 90 days
                          </Text>
                        </li>
                        <li>
                          <Text>
                            Your friend might be ineligible to be a Heard member
                            if we can&apos;t support their practice needs for
                            one or more of the following reasons:
                            <ul>
                              <li>International taxes</li>
                              <li>Multiple practice owners</li>
                              <li>C-Corp tax entity</li>
                              <li>Practice with more than 10 employees</li>
                              <li>
                                Combined personal and business banking activity
                              </li>
                            </ul>
                          </Text>
                        </li>
                      </ul>
                    </>
                  }
                  variant="text"
                />
              </GridRowColumn>
              <GridRowColumn>
                <Accordion
                  title="I'm a new customer who has just joined Heard. Can I still refer friends to join the platform?"
                  content={
                    <Text>
                      Of course! We want you to share with your network to reach
                      and help as many therapists as we can.
                    </Text>
                  }
                  variant="text"
                />
              </GridRowColumn>
            </Grid>
          </Card>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row />
    </Grid>
  )
}

export default Referrals
