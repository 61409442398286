import { Grid, Image } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import { useReselector } from '../../../../../utils/sharedHooks'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { selectFederalEstimateByTaxQuarter } from '../../userTaxEstimates.selector'
import {
  CALCULATE_SAFE_HARBOR_STEP,
  useCalculateSafeHarborSteps,
} from './helpers'
import { selectIsCurrentUserScorp } from '../../../../../selectors/user.selectors'
import {
  SafeHarborType,
  UPDATE_USER_TAX_ESTIMATES_KEY,
  updateUserTaxEstimates,
} from '../../userTaxEstimates.slice'
import {
  Alert,
  FormikInput,
  GridRowColumn,
  Text,
  getFieldName,
  makeNumberSchema,
  FormikRadioToggleButton,
  FormikLabelError,
  makeReqBoolSchema,
  Card,
} from '../../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../../components/BaseComponents/Grid'
import { getFetchError } from '../../../../../reducers/fetch'
import TaxProfileAccordions, {
  useTaxProfileAccordionCopy,
} from '../../../TaxesProfile/FormFlow/TaxProfileAccordions'
import CalculateSafeHarborFooter from './CalculateSafeHarborFooter'
import {
  centsToDollars,
  dollarsToCents,
} from '../../../../../utils/currencyHelpers'
import { QuarterlyTaxEstimateDetail } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'

const QuarterlyTaxRecommendations = ({
  details,
}: {
  details?: QuarterlyTaxEstimateDetail | null
}) => {
  const dispatch = useAppDispatch()
  const isScorp = useReselector(selectIsCurrentUserScorp)

  const { goToPreviousStep, goToNextStep } = useCalculateSafeHarborSteps(
    CALCULATE_SAFE_HARBOR_STEP.quarterlyTaxRecommendations
  )

  const federalTaxEstimate = useReselector(
    selectFederalEstimateByTaxQuarter,
    `${details?.taxYear}-${details?.taxQuarter}`
  )

  const error = useReselector(getFetchError, UPDATE_USER_TAX_ESTIMATES_KEY)

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      didReceiveRecommendations: federalTaxEstimate?.safeHarborType
        ? federalTaxEstimate?.safeHarborType === SafeHarborType.ES
        : null,
      estimatedTaxAmount:
        federalTaxEstimate?.safeHarborType === SafeHarborType.ES &&
        federalTaxEstimate?.estimateInCents
          ? centsToDollars(federalTaxEstimate.estimateInCents)
          : null,
    },
    onSubmit: async ({ didReceiveRecommendations, estimatedTaxAmount }) => {
      if (!didReceiveRecommendations) {
        goToNextStep()
        return
      }

      if (!federalTaxEstimate) {
        return
      }

      const res = await dispatch(
        updateUserTaxEstimates(federalTaxEstimate?.id, {
          estimateInCents: dollarsToCents(estimatedTaxAmount),
          safeHarborType: SafeHarborType.ES,
        })
      )

      if (res) {
        goToNextStep(CALCULATE_SAFE_HARBOR_STEP.finish)
      }
    },
  })

  const { values, isSubmitting, isValid, submitForm } = formik

  const previousYear = Number(details?.taxYear) - 1

  const faqCopy = useTaxProfileAccordionCopy()

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/1040_es.png"
            style={{ width: '100%' }}
          />
        </GridRowColumn>

        <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
          <Text as="display2" textAlign="center">
            Did you receive quarterly tax recommendations in {previousYear}?
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
          <Text as="bodyLg">
            Some tax services will provide you with a quarterly tax estimate for
            the coming year with your 1040 tax return. Look for a form called{' '}
            <b>1040-ES Estimated Tax</b>, or any instructions attached to your
            return for recommended payments. Look for the 1040-ES payment
            voucher or recommended amount for the current quarter. If there is
            an estimate provided there, you can use this number for your
            quarterly tax payment.
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <FormikLabelError
            name={getFieldName<typeof values>('didReceiveRecommendations')}
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <Grid.Row>
          <Grid.Column width={4} />
          <Grid.Column width={4}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName<typeof values>('didReceiveRecommendations')}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={4}>
            <FormikRadioToggleButton
              fullWidth
              name={getFieldName<typeof values>('didReceiveRecommendations')}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        {error && (
          <GridRowColumn>
            <Alert type="error">{error.message}</Alert>
          </GridRowColumn>
        )}

        {values.didReceiveRecommendations && (
          <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
            <FormikInput
              name={getFieldName<typeof values>('estimatedTaxAmount')}
              fullWidth
              componentType="currency"
              schema={makeNumberSchema({
                allowedDecimals: 2,
                greaterThanZero: true,
              })}
              label={`Q${details?.taxQuarter} Estimated Tax`}
              description="Enter the suggested amount from your 1040-ES."
              placeholder="$"
            />
          </GridRowColumn>
        )}

        {values.didReceiveRecommendations === false && (
          <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
            <Card type="section" backgroundColor="stone40">
              <Grid>
                <Grid.Row verticalAlign="middle">
                  <Grid.Column width={3}>
                    <Image
                      src="https://heard-images.s3.amazonaws.com/assets/ledger.svg"
                      style={{ height: 80, width: 80 }}
                    />
                  </Grid.Column>
                  <Grid.Column width={11}>
                    <Text>
                      No worries! Proceed to the next screen, and we’ll guide
                      you through getting an estimate yourself. Keep that 1040
                      tax return handy...
                    </Text>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card>
          </GridRowColumn>
        )}

        <Grid.Row />
        <Grid.Row />
        <TaxProfileAccordions
          faqs={[
            faqCopy.whoPaysQtes,
            faqCopy.no1040TaxReturn,
            ...(isScorp ? [faqCopy.haveToPayQTEsScorp] : []),
          ]}
        />

        <CalculateSafeHarborFooter
          submitOrContinue={submitForm}
          goToPreviousStep={goToPreviousStep}
          continueDisabled={!isValid || isSubmitting}
          loading={isSubmitting}
          nextText={values.didReceiveRecommendations ? 'Save' : 'Continue'}
        />
      </Grid>
    </FormikProvider>
  )
}

export default QuarterlyTaxRecommendations
