import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import Lottie from 'lottie-react'
import { DateTime } from 'luxon'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectCurrentAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../utils/dateHelpers'
import TaxSurveyOptOutModal from '../components/TaxSurveyOptOutModal'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import taxWelcome from '../../../../assets/lottie/taxWelcome.json'

const NoTaxFilingPanel = () => {
  const annualTaxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const navigate = useNavigate()
  const [optOutModalOpen, setOptOutModalOpen] = useState(false)
  const year = useReselector(selectCurrentAnnualTaxYear)

  const tskEnabled = Boolean(
    annualTaxDetails?.taxSeasonKickoffStartAt &&
      isoToUTCDateTime(annualTaxDetails?.taxSeasonKickoffStartAt) <
        DateTime.now()
  )

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Lottie animationData={taxWelcome} />
      </GridRowColumn>
      <GridRowColumn centerContent {...makeGridConfig([10, 12], true)} short>
        <Text as="display2" color="forest" textAlign="center">
          Start your {year} annual taxes journey with us
        </Text>
      </GridRowColumn>
      <GridRowColumn {...makeGridConfig([10, 12], true)}>
        <Text as="bodyLg" textAlign="center">
          We’re here to help guide the way. You’ll answer a few questions to
          personalize your experience with our Tax Season Kickoff, and we’ll
          share what to expect for this tax season.
        </Text>
      </GridRowColumn>
      <GridRowColumn centerContent spacer={3} width={10}>
        <Button
          onClick={() => navigate(`/taxes/annual/annual_tax_needs/${year}`)}
          disabled={!tskEnabled}
        >
          {tskEnabled ? "Let's go" : 'Coming Soon'}
        </Button>
      </GridRowColumn>

      {tskEnabled && (
        <>
          <GridRowColumn centerContent>
            <Button
              variant="actionLink"
              onClick={() => setOptOutModalOpen(true)}
            >
              I don’t need Heard to prepare my taxes
            </Button>
          </GridRowColumn>
          <GridRowColumn centerContent {...makeGridConfig([10, 12], true)}>
            <Card type="subsection" backgroundColor="stone">
              <Text>
                <Icon icon={regular('timer')} style={{ marginRight: 20 }} />
                This should take about 3 minutes
              </Text>
              <Text>
                <Icon icon={regular('files')} style={{ marginRight: 20 }} />
                No documents required
              </Text>
              <Text>
                <Icon
                  icon={regular('flag-checkered')}
                  style={{ marginRight: 20 }}
                />
                Due{' '}
                {annualTaxDetails?.taxSeasonKickoffDueAt &&
                  isoToUTCDateTime(
                    annualTaxDetails.taxSeasonKickoffDueAt
                  ).toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}
              </Text>
            </Card>
          </GridRowColumn>
        </>
      )}

      <TaxSurveyOptOutModal
        open={optOutModalOpen}
        close={() => setOptOutModalOpen(false)}
        year={year}
      />
    </Grid>
  )
}

export default NoTaxFilingPanel
