import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { Grid, Loader } from 'semantic-ui-react'

import SupportCenterCard from './SupportCenterCard'
import { fetchUserTransactions } from '../Transactions/transactions.slice'
import { fetchTransactionCategoriesIfNeeded } from '../Reports/reports.slice'
import { fetchFinancialAccountsIfNeeded } from '../../actions/financialAccountActions'
import { fetchAllocationRulesIfNeeded } from '../../actions/allocationRuleActions'
import { fetchFinancialProfileActionItems } from './financialProfileActionItems.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../Admin/UserDocumentCategories/userDocumentCategories.slice'

import UserImportantDatesCard from './UserImportantDates'
import { SIGNUP_PATHS } from '../Signup/helpers'
import { fetchUserActionItemsIfNeeded } from './UserActionItems/userActionItems.slice'
import UserActionItemsCard from './UserActionItems'
import { fetchUserDocuments } from '../UserDocuments/userDocuments.slice'

import { useReselector } from '../../utils/sharedHooks'
import {
  getCurrentUser,
  getIsOnboarding,
  selectIsReactivating,
  selectIsSigningUp,
} from '../../selectors/user.selectors'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import PageHeader from '../../components/shared/PageHeader'
import WebinarCard from './WebinarCard'
import PayrollCard from './PayrollCard'
import { fetchPayrollProfileIfNeeded } from '../Payroll/payrollActions'
import { selectShouldShowPayrollUpsellCard } from '../Payroll/payroll.selectors'
import { SCorpUpsellCard } from './SCorpUpsell/SCorpUpsellCard'
import { fetchSCorpElectionStatus } from './SCorpUpsell/sCorpActions'
import AnnualTaxesStatusCard from './AnnualTaxesStatusCard'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'
import UsersnapTaxSeasonNPSSurvey from '../Taxes/AnnualTaxes/UsersnapTaxSeasonNPSSurvey'
import { TaxAdvisoryPilot } from '../Taxes/Consultations/PilotBanner'
import { useAppDispatch } from '../../utils/typeHelpers'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { fetchAllEoyReviewStepsIfNeeded } from '../Taxes/AnnualTaxes/TaxChecklist/Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import { fetchAnnualTaxFilingFormsIfNeeded } from '../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { fetchAnnualTaxFilingsIfNeeded } from '../Taxes/AnnualTaxes/annualTaxFilings.slice'
import { fetchUserEoyReviewProgress } from '../Taxes/AnnualTaxes/TaxChecklist/Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { selectCurrentAnnualTaxYear } from '../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectShowLateFilingAlert } from '../Taxes/AnnualTaxes/annualTaxFilings.selector'
import { LateFilingAlert } from '../Taxes/AnnualTaxes/components/LateFilingAlert'

const Finances = () => {
  const dispatch = useAppDispatch()
  const [fetching, setFetching] = useState(true)
  const currentUser = useReselector(getCurrentUser)
  const isOnboarding = useReselector(getIsOnboarding)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const isReactivating = useReselector(selectIsReactivating)
  const isSigningUp = useReselector(selectIsSigningUp)
  const shouldDisplayPayrollUpsellCard = useReselector(
    selectShouldShowPayrollUpsellCard
  )
  const shouldDisplaySCorpUpsellCard = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.scorpUpsellCard,
    false
  )

  const taxYear = useReselector(selectCurrentAnnualTaxYear)

  const showLateFilingAlert = useReselector(selectShowLateFilingAlert)

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(fetchSCorpElectionStatus(currentUser.id))
    }
  }, [dispatch, currentUser?.id])

  useEffect(() => {
    async function batchFetch() {
      await Promise.all([
        dispatch(fetchFinancialProfileActionItems()),
        dispatch(fetchUserTransactions()),
        dispatch(fetchTransactionCategoriesIfNeeded()),
        dispatch(fetchFinancialAccountsIfNeeded()),
        dispatch(fetchAllocationRulesIfNeeded()),
        dispatch(fetchUserDocuments()),
        dispatch(fetchUserDocumentCategoriesIfNeeded()),
        dispatch(
          fetchUserActionItemsIfNeeded({
            forceFetch: true,
          })
        ),
        dispatch(fetchPayrollProfileIfNeeded()),
        //remove the below fetches if LateFilingAlert is ever removed
        dispatch(fetchAnnualTaxFilingFormsIfNeeded()),
        dispatch(fetchAnnualTaxFilingsIfNeeded()),
        dispatch(fetchAllAnnualTaxDetailsIfNeeded()),
        dispatch(fetchAllEoyReviewStepsIfNeeded()),
      ])
      setFetching(false)
    }
    batchFetch()
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchUserEoyReviewProgress(taxYear))
  }, [dispatch, taxYear])

  // If admin, we want to redirect to the admin dashboard
  if (currentUser?.admin) {
    return <Navigate to="/admin/finances" />
  }

  // Else, check if user is in the middle of signing up or resigning up.
  // If so, redirect them to continue
  // Remove isReactivating in in CORE-253
  if (isReactivating || isSigningUp) {
    return <Navigate to={SIGNUP_PATHS.aboutYourPractice} />
  }

  return fetching ? (
    <Loader active />
  ) : isOnboarding ? (
    <Navigate to={'/onboarding-v2'} />
  ) : (
    <>
      {showLateFilingAlert && (
        <>
          <LateFilingAlert />
          <br />
        </>
      )}
      {!isMobile && (
        <PageHeader header={`Welcome back, ${currentUser?.firstName}.`} />
      )}
      <Grid stackable>
        <Grid.Row>
          <Grid.Column computer={11} tablet={16}>
            <UserActionItemsCard />
          </Grid.Column>
          <Grid.Column computer={5} tablet={16}>
            <TaxAdvisoryPilot />
            <AnnualTaxesStatusCard />
            {shouldDisplayPayrollUpsellCard && <PayrollCard />}
            {shouldDisplaySCorpUpsellCard && <SCorpUpsellCard />}
            <UserImportantDatesCard />
            <WebinarCard />
            <SupportCenterCard />
          </Grid.Column>
        </Grid.Row>
        <UsersnapTaxSeasonNPSSurvey />
      </Grid>
    </>
  )
}

export default Finances
