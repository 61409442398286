import { DateTime } from 'luxon'
import { Icon, Table, Text } from '../../../BaseComponents'
import { VirtualStatementTransaction } from '../../../../actions/admin/accountReconciliationActions'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import { centsToDollars } from '../../../../utils/currencyHelpers'
import CurrencyFormatLabel from '../../../shared/CurrencyFormatLabel'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Colors } from '../../../../styles/theme'

export const VirtualStatementTransactionTable = ({
  statement,
}: {
  statement: VirtualStatementTransaction[]
}) => {
  return (
    <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.Cell>
              <Text as="eyebrow" color="darkGray">
                Transaction Date
              </Text>
            </Table.Cell>
            <Table.Cell>
              <Text as="eyebrow" color="darkGray">
                Transaction Description
              </Text>
            </Table.Cell>
            <Table.Cell>
              <Text as="eyebrow" color="darkGray">
                Transaction Amount
              </Text>
            </Table.Cell>
            <Table.Cell>
              <Text as="eyebrow" color="darkGray">
                Plaid Bank Balance
              </Text>
            </Table.Cell>
            <Table.Cell>
              <Text as="eyebrow" color="darkGray">
                Heard Running Balance
              </Text>
            </Table.Cell>
            <Table.Cell>
              <Text as="eyebrow" color="darkGray">
                Reconciliation Delta
              </Text>
            </Table.Cell>
            <Table.Cell>
              <Text as="eyebrow" color="darkGray">
                Status
              </Text>
            </Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {statement.map((transaction) => (
            <Table.Row
              style={{
                backgroundColor: transaction.potentialBreak
                  ? Colors.lightRed
                  : undefined,
              }}
              key={transaction.transactionId}
            >
              <Table.Cell>
                <Text>
                  {DateTime.fromISO(transaction.date).toFormat(
                    DATE_FORMATS_LUXON.DISPLAY_SHORT
                  )}
                </Text>
              </Table.Cell>
              <Table.Cell>
                <Text>{transaction.description}</Text>
              </Table.Cell>
              <Table.Cell>
                <Text>
                  <CurrencyFormatLabel
                    value={centsToDollars(transaction.amountInCents)}
                  />
                </Text>
              </Table.Cell>
              <Table.Cell>
                {transaction.displayStatus && (
                  <Text>
                    <CurrencyFormatLabel
                      value={centsToDollars(transaction.balanceInCents)}
                    />
                  </Text>
                )}
              </Table.Cell>
              <Table.Cell>
                {transaction.displayStatus && (
                  <Text>
                    <CurrencyFormatLabel
                      value={centsToDollars(
                        (transaction.balanceInCents || 0) -
                          (transaction.deltaInCents || 0)
                      )}
                    />
                  </Text>
                )}
              </Table.Cell>
              <Table.Cell>
                {transaction.displayStatus && (
                  <Text color={transaction.potentialBreak ? 'red' : undefined}>
                    <CurrencyFormatLabel
                      value={centsToDollars(transaction.deltaInCents)}
                    />
                  </Text>
                )}
              </Table.Cell>
              <Table.Cell>
                {transaction.displayStatus &&
                  (transaction.potentialBreak ? (
                    <Icon icon={solid('circle-x')} color="red" />
                  ) : (
                    <Icon icon={solid('circle-check')} color="green" />
                  ))}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}
