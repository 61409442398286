import { useEffect, useMemo, useState } from 'react'
import parse from 'html-react-parser'
import {
  Divider,
  Dropdown as SemanticDropdown,
  Grid,
  Icon as SemanticIcon,
  Loader,
} from 'semantic-ui-react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DateTime } from 'luxon'

import {
  Button,
  Card,
  Dropdown,
  GridRowColumn,
  Icon,
  Text,
} from '../../components/BaseComponents'
import { DATE_FORMATS_LUXON } from '../../utils/dateHelpers'
import Tooltip from '../Taxes/QuarterlyTaxEstimates/components/Tooltip'
import {
  fetchWebinarEvents,
  WebinarEvent,
} from '../Resources/resources.actions'
import { useAppDispatch } from '../../utils/typeHelpers'

const WEBINARS_TO_SHOW = 3

const WebinarCard = () => {
  const dispatch = useAppDispatch()
  const [fetching, setFetching] = useState(true)
  const [webinars, setWebinars] = useState<WebinarEvent[]>()
  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      setFetching(true)
      const res = await fetchWebinarEvents()(dispatch)
      setWebinars(res)
    }
    fetch()
    setFetching(false)
  }, [dispatch])
  // Filter out past events
  const filteredEvents = useMemo(
    () =>
      webinars?.filter(
        (event: WebinarEvent) =>
          DateTime.fromISO(event.start.dateTime) > DateTime.now()
      ),
    [webinars]
  )

  useEffect(() => {
    // Hide show more if we are under limit
    if (filteredEvents && filteredEvents.length <= WEBINARS_TO_SHOW) {
      setShowMore(true)
    }
  }, [filteredEvents, filteredEvents?.length])

  const eventsToShow = useMemo(() => {
    return showMore
      ? filteredEvents
      : filteredEvents?.slice(0, WEBINARS_TO_SHOW)
  }, [filteredEvents, showMore])

  return (
    <Card type="subsection" backgroundColor="stone40">
      {fetching && <Loader />}
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <Text as="h2">Member Events</Text>
          </Grid.Column>
          <Grid.Column floated="right" width={2} only="computer">
            <Tooltip
              popup={{
                title: 'Webinars',
                body: "Here's an overview of our upcoming webinars. Please register for any that catch your interest.\n",
              }}
              labelComponent={<SemanticIcon name="question circle outline" />}
            />
          </Grid.Column>
        </Grid.Row>

        {!eventsToShow ||
          (eventsToShow.length === 0 && (
            <GridRowColumn>
              <Text as="bodySm">No upcoming webinars. Check back soon!</Text>
            </GridRowColumn>
          ))}

        {eventsToShow &&
          eventsToShow.length > 0 &&
          eventsToShow.map(({ title, description, start, end, url }) => {
            const localDate = DateTime.fromISO(start.dateTime).setZone(
              start.timeZone
            )

            return (
              <GridRowColumn key={title}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={14} style={{ overflow: 'hidden' }}>
                      <Text as="h3">{title}</Text>
                      <Text as="bodySm">{parse(description)}</Text>
                      <Text as="bodySm" color="oak">
                        {localDate.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}
                      </Text>
                      <Text as="bodySm" color="oak">
                        {localDate.toFormat(DATE_FORMATS_LUXON.TIME)}
                      </Text>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Dropdown
                        direction="left"
                        variant="text"
                        icon="ellipsis horizontal"
                      >
                        <SemanticDropdown.Menu>
                          <SemanticDropdown.Item
                            onClick={() =>
                              window.open(
                                `https://calendar.google.com/calendar/u/0/r/eventedit?text=${encodeURI(
                                  title
                                )}&dates=${localDate.toISO({
                                  format: 'basic',
                                })}%2F${DateTime.fromISO(end.dateTime)
                                  .setZone(end.timeZone)
                                  .toISO({
                                    format: 'basic',
                                  })}&location=${encodeURI(url)}`,
                                '_newtab'
                              )
                            }
                          >
                            <Text>
                              <Icon
                                icon={solid('calendar')}
                                color="green"
                                style={{ marginRight: 10 }}
                              />
                              Add to Calendar
                            </Text>
                          </SemanticDropdown.Item>
                        </SemanticDropdown.Menu>
                      </Dropdown>
                    </Grid.Column>
                  </Grid.Row>
                  <Divider style={{ margin: '0 1rem' }} />
                </Grid>
              </GridRowColumn>
            )
          })}
        {!showMore && filteredEvents && (
          <GridRowColumn>
            <Button variant="secondaryLink" onClick={() => setShowMore(true)}>
              View more
            </Button>
          </GridRowColumn>
        )}
      </Grid>
    </Card>
  )
}

export default WebinarCard
