import { useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  GridRowColumn,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import AnnualTaxImportantDateCard from './AnnualTaxImportantDateCard'
import AnnualTaxFaq from './AnnualTaxFaq'
import AnnualTaxFilingStatusCard from './AnnualTaxFilingStatusCard'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  getAnnualTaxFilingForYearSelector,
  selectAllowEditTaxSeasonKickoffForYear,
  selectShowNeedContractorFilingByYear,
} from '../annualTaxFilings.selector'
import {
  selectCurrentAnnualTaxYear,
  selectTaxDetailsByYear,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import Ten99Card from '../Ten99Filing/TaxCenterCard'
import TaxCenterPromptCard, {
  PromptCardStatus,
} from '../components/TaxCenterPromptCard'
import { fetchAnnualTaxFilingFormsIfNeeded } from '../annualTaxFilingForms.slice'
import { fetchUserActionItemsIfNeeded } from '../../../Dashboard/UserActionItems/userActionItems.slice'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../../utils/dateHelpers'
import {
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../../../utils/deviceWidthHelpers'
import { TAX_SURVEY_CONTENT_SCREENS } from '../Survey/AnnualTaxSurvey'
import MessageCardList from '../TaxFyle/MessageCardList'
import ExtensionSurveyCard from '../ExtensionSurvey/ExtensionSurveyCard'
import {
  FETCH_USER_TAX_QUESTIONNAIRE_KEY,
  fetchUserTaxQuestionnaire,
} from '../TaxChecklist/taxChecklistQuestion.actions'
import { selectIsFetchingForKeys } from '../../../../reducers/fetch'
import FormPromptCards from './FormPromptCards'
import { fetchAboundTaxDocumentsForPayer } from '../aboundAnnualTaxFilings.slice'
import ExtensionAlert from '../ExtensionSurvey/ExtensionAlert'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { fetchAnnualTaxFilingsIfNeeded } from '../annualTaxFilings.slice'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { fetchUserEoyReviewProgress } from '../TaxChecklist/Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { fetchAllEoyReviewStepsIfNeeded } from '../TaxChecklist/Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'

const AnnualTaxPostSurveyCard = ({ year }: { year: string }) => {
  const annualTaxDetails = useReselector(selectTaxDetailsByYear, year)
  const allowEditSurvey = useReselector(
    selectAllowEditTaxSeasonKickoffForYear,
    year
  )

  return (
    <TaxCenterPromptCard
      body={{
        // This is a special case of this prompt card b/c we display a completely
        // different screen for the first three states. We only care about the done
        // state for this particular panel, but theoretically this component should
        // handle all four states to maintain consistency.
        [PromptCardStatus.COMING_SOON]: null,
        [PromptCardStatus.ACTION_REQUIRED]: null,
        [PromptCardStatus.IN_PROGRESS]: null,
        [PromptCardStatus.DONE]: allowEditSurvey ? (
          <Text>
            Need to make changes? You have until{' '}
            {annualTaxDetails?.taxSeasonKickoffDueAt &&
              formatISOFromUTC(
                annualTaxDetails.taxSeasonKickoffDueAt,
                DATE_FORMATS_LUXON.DISPLAY_LONG
              )}{' '}
            to{' '}
            <Link
              to={`/taxes/annual/annual_tax_needs/${year}?screen=${TAX_SURVEY_CONTENT_SCREENS.review}`}
            >
              edit your answers
            </Link>
            .
          </Text>
        ) : null,
      }}
      icon={regular('calendar-day')}
      status={PromptCardStatus.DONE}
      title="You’ve completed the Tax Season Kickoff!"
    />
  )
}

const IncompleteTaxFilingFormPanel = () => {
  const year = useReselector(selectCurrentAnnualTaxYear)
  const dispatch = useAppDispatch()
  const taxFiling = useReselector(getAnnualTaxFilingForYearSelector, year)
  const showContractorFiling = useReselector(
    selectShowNeedContractorFilingByYear,
    year
  )
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)
  const isFetchingQuestionnaire = useReselector(selectIsFetchingForKeys, [
    FETCH_USER_TAX_QUESTIONNAIRE_KEY(year),
  ])
  const allowEditSurvey = useReselector(
    selectAllowEditTaxSeasonKickoffForYear,
    year
  )

  useEffect(() => {
    dispatch(fetchUserActionItemsIfNeeded())
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAboundTaxDocumentsForPayer())
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchAllEoyReviewStepsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchUserTaxQuestionnaire(year))
    dispatch(fetchUserEoyReviewProgress(year))
  }, [dispatch, year])

  if (!taxFiling) {
    return null
  }

  return (
    <Grid>
      <AnnualTaxFilingStatusCard year={year} />
      <Grid.Row>
        <Grid.Column width={isMobileOrTablet ? 16 : 10}>
          <Grid>
            <ExtensionAlert is1040 />
            <ExtensionAlert is1040={false} />
            <MessageCardList inTaxCenter />
            {allowEditSurvey && (
              <GridRowColumn>
                <AnnualTaxPostSurveyCard year={year} />
              </GridRowColumn>
            )}
            {!isFetchingQuestionnaire && <ExtensionSurveyCard year={year} />}
            <FormPromptCards year={year} />
            {showContractorFiling && (
              <GridRowColumn>
                <Ten99Card year={year} />
              </GridRowColumn>
            )}
            {!allowEditSurvey && (
              <GridRowColumn>
                <AnnualTaxPostSurveyCard year={year} />
              </GridRowColumn>
            )}
          </Grid>
        </Grid.Column>
        <Grid.Column width={isMobileOrTablet ? 16 : 6}>
          <Grid>
            <GridRowColumn>
              <AnnualTaxImportantDateCard year={year} />
            </GridRowColumn>
            <GridRowColumn>
              <AnnualTaxFaq />
            </GridRowColumn>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default IncompleteTaxFilingFormPanel
