import { useEffect, useMemo, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  Card,
  GridColumnSpacer,
  GridRowColumn,
  Label,
  Link,
  Text,
  Icon,
  Popup,
} from '../../../../../components/BaseComponents'
import { formatCurrencyFromCents } from '../../../../../utils/currencyHelpers'
import UpdateQuarterlyTaxEstimateModal from '../UpdateQuarterlyTaxEstimateModal'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectStatusForLateJoinerTaxEstimate } from '../../userTaxEstimates.selector'
import { DATE_FORMATS_LUXON } from '../../../../../utils/dateHelpers'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { fetchUserDocuments } from '../../../../UserDocuments/userDocuments.slice'
import { getUserDocumentById } from '../../../../UserDocuments/userDocuments.selector'
import { Fonts } from '../../../../../styles/theme'
import { EstimateStatus } from '../../service'
import useTaxPaymentInfoFlyout from '../TaxPaymentInfoFlyout'
import { splitTaxYearQuarter } from '../../helpers'
import { SafeHarborType, UserTaxEstimate } from '../../userTaxEstimates.slice'
import { WhatIsSafeHarborPopup } from './helpers'

const WhatIsEs1040Popup = () => (
  <Popup
    trigger={
      <Button variant="tooltipLink">Where did this number come from?</Button>
    }
    content={
      <>
        <Text as="h3">Where did this number come from?</Text>
        <br />
        <Text>
          When you first joined Heard, we took you through some questions to
          figure out your quarterly estimate. We asked you to look at last
          year’s 1040 Individual Tax Return and find the 1040-ES payment voucher
          to find the suggested amount.
        </Text>
      </>
    }
    popupStyle={{ maxWidth: 400 }}
  />
)

const SafeHarborPaymentCard = ({
  federalTaxEstimate,
}: {
  federalTaxEstimate: UserTaxEstimate
}) => {
  const dispatch = useAppDispatch()
  const [estimateModalOpen, setEstimateModalOpen] = useState(false)

  const { quarter } = splitTaxYearQuarter(federalTaxEstimate?.taxQuarter)

  const openFlyout = useTaxPaymentInfoFlyout({
    estimateId: federalTaxEstimate?.id,
    openQTEModal: () => setEstimateModalOpen(true),
  })

  const status = useReselector(
    selectStatusForLateJoinerTaxEstimate,
    federalTaxEstimate?.id
  )

  const document = useReselector(
    getUserDocumentById,
    federalTaxEstimate?.userDocumentId
  )

  useEffect(() => {
    dispatch(fetchUserDocuments())
  }, [dispatch])

  const conditionalContent = useMemo(() => {
    switch (status) {
      case EstimateStatus.unpaidWithResponse:
        return {
          label: <Label color="neutral">No payment</Label>,
          bottomContent: (
            <>
              <GridRowColumn>
                <Card backgroundColor="stone">
                  <Text color="oak">
                    Thanks for letting us know you did not make a payment this
                    quarter.
                  </Text>
                </Card>
              </GridRowColumn>
              <GridRowColumn>
                <Button
                  onClick={() => setEstimateModalOpen(true)}
                  variant="secondaryLink"
                >
                  Edit Payment
                </Button>
              </GridRowColumn>
            </>
          ),
        }
      case EstimateStatus.estimateIsZero:
        return {
          label: <Label color="orange">Ready for Payment</Label>,
          bottomContent: (
            <>
              <GridRowColumn>
                <Card backgroundColor="lightGreen">
                  <Text color="accentGreen">
                    Please confirm how much you paid (even if this is $0) using
                    the “Update status” button.
                  </Text>
                </Card>
              </GridRowColumn>
              <GridRowColumn short>
                <Popup
                  trigger={
                    <Button variant="tooltipLink">
                      Why is my estimate $0?
                    </Button>
                  }
                  content={
                    <>
                      <Text as="h3">Why is my estimate $0?</Text>
                      <br />
                      <Text>
                        Since you indicated your tax liability for last year was
                        $0, you are not required to pay quarterly taxes. If that
                        number isn&apos;t right, you can edit your responses and
                        provide the correct tax liability.
                      </Text>
                    </>
                  }
                />
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  We need this to provide accurate estimates for upcoming
                  quarters
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  Need to edit estimate? Click{' '}
                  <Link
                    to="/taxes/quarterly/calculate-safe-harbor"
                    as="secondaryLink"
                  >
                    here
                  </Link>
                  .
                </Text>
              </GridRowColumn>
              <Grid.Row>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <Button fullWidth onClick={openFlyout}>
                    View & Pay
                  </Button>
                </Grid.Column>
                <GridColumnSpacer mobile />
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <Button
                    fullWidth
                    variant="secondary"
                    onClick={() => setEstimateModalOpen(true)}
                  >
                    Update status
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </>
          ),
        }
      case EstimateStatus.unpaidWithoutResponse:
      case EstimateStatus.readyForPayment:
        return {
          label: <Label color="orange">Ready for Payment</Label>,
          bottomContent: (
            <>
              <GridRowColumn>
                {federalTaxEstimate?.safeHarborType === SafeHarborType.ES ? (
                  <WhatIsEs1040Popup />
                ) : (
                  <WhatIsSafeHarborPopup />
                )}
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  Need to edit estimate? Click{' '}
                  <Link
                    to="/taxes/quarterly/calculate-safe-harbor"
                    as="secondaryLink"
                  >
                    here
                  </Link>
                  .
                </Text>
              </GridRowColumn>
              <Grid.Row>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <Button fullWidth onClick={openFlyout}>
                    View & Pay
                  </Button>
                </Grid.Column>
                <GridColumnSpacer mobile />
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <Button
                    fullWidth
                    variant="secondary"
                    onClick={() => setEstimateModalOpen(true)}
                  >
                    Update status
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </>
          ),
        }
      case EstimateStatus.paid: {
        if (!federalTaxEstimate?.userDocumentId) {
          return {
            label: <Label color="green">Paid</Label>,
            bottomContent: (
              <>
                <GridRowColumn>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                    }}
                  >
                    <Icon
                      icon={solid('check')}
                      style={{ marginLeft: 3, height: 15, marginRight: 3 }}
                      color="green"
                    />
                    {federalTaxEstimate?.paidAt && (
                      <Text as="bodySm">
                        paid{' '}
                        {DateTime.fromISO(federalTaxEstimate?.paidAt).toFormat(
                          DATE_FORMATS_LUXON.DISPLAY_LONG
                        )}
                      </Text>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                    }}
                  >
                    <Icon
                      icon={solid('xmark')}
                      style={{ marginLeft: 3, height: 15, marginRight: 3 }}
                      color="red"
                    />
                    <Text
                      as="bodySm"
                      style={{ textDecorationLine: 'underline' }}
                    >
                      add missing receipt
                    </Text>
                  </div>
                </GridRowColumn>
                <GridRowColumn>
                  <Button
                    onClick={() => setEstimateModalOpen(true)}
                    variant="secondaryLink"
                  >
                    Update Payment
                  </Button>
                </GridRowColumn>
              </>
            ),
          }
        }

        return {
          label: <Label color="green">Paid</Label>,
          bottomContent: (
            <>
              <GridRowColumn>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <Icon
                    icon={solid('check')}
                    style={{ marginLeft: 3, height: 15, marginRight: 3 }}
                    color="green"
                  />
                  {federalTaxEstimate?.paidAt && (
                    <Text as="bodySm">
                      paid{' '}
                      {DateTime.fromISO(federalTaxEstimate?.paidAt).toFormat(
                        DATE_FORMATS_LUXON.DISPLAY_LONG
                      )}
                    </Text>
                  )}
                </div>
                {document?.signedUrl && (
                  <Link newPage href={document.signedUrl}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                      }}
                    >
                      <Icon
                        icon={solid('check')}
                        style={{ marginLeft: 3, height: 15, marginRight: 3 }}
                        color="green"
                      />
                      <Button variant="secondaryLink" style={Fonts.bodySm}>
                        receipt uploaded
                      </Button>
                    </div>
                  </Link>
                )}
              </GridRowColumn>
              <GridRowColumn>
                <Button
                  onClick={() => setEstimateModalOpen(true)}
                  variant="secondaryLink"
                >
                  Update Payment
                </Button>
              </GridRowColumn>
            </>
          ),
        }
      }
      case EstimateStatus.upcoming:
      case EstimateStatus.awaitingEstimate:
      case EstimateStatus.noIncomeTax:
      case EstimateStatus.joinedTooLate:
        return null
      default:
        return status satisfies never
    }
  }, [status, federalTaxEstimate, document, openFlyout])

  if (!federalTaxEstimate) {
    return null
  }

  return (
    <Grid>
      <Grid.Column width={7}>
        <Card
          backgroundColor="natural"
          type="subsection"
          unsetMobileMargin={-20}
        >
          <Grid>
            <GridRowColumn>{conditionalContent?.label}</GridRowColumn>
            <GridRowColumn short>
              <Text as="eyebrow" color="darkGray">
                federal
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text as="display">
                {formatCurrencyFromCents(federalTaxEstimate.estimateInCents, {
                  hideDecimalsIfZero: true,
                })}
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text as="bodySm" color="darkGray">
                {federalTaxEstimate.safeHarborType === SafeHarborType.ES
                  ? `Q${quarter} 1040-ES estimate`
                  : 'Safe harbor estimate'}
              </Text>
            </GridRowColumn>
            {conditionalContent?.bottomContent}
          </Grid>
          <UpdateQuarterlyTaxEstimateModal
            open={estimateModalOpen}
            close={() => setEstimateModalOpen(false)}
            estimate={federalTaxEstimate}
          />
        </Card>
      </Grid.Column>
    </Grid>
  )
}

export default SafeHarborPaymentCard
