import { SyntheticEvent, useEffect, useState } from 'react'
import { Container, Form, Grid, Image } from 'semantic-ui-react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input'

import {
  selectFirstErrorMessageForKeys,
  selectIsFetchingForKeys,
} from '../../reducers/fetch'
import {
  loginUser,
  loginRegenerateOTP,
  POST_LOGIN_KEY,
  POST_LOGIN_SSO_KEY,
  loginUserSso,
} from '../../actions/authActions'
import './Login.scss'
import {
  Button,
  Input,
  Text,
  Link,
  Alert,
  Card,
  GridRowColumn,
} from '../BaseComponents'
import { Colors, Fonts } from '../../styles/theme'
import { useReselector } from '../../utils/sharedHooks'
import {
  useAnalyticsSetUserId,
  useAnalyticsTrack,
  useAnalyticsView,
} from '../../features/Amplitude'
import MaintenanceBanner from '../Banners/MaintenanceBanner'
import { useAppDispatch } from '../../utils/typeHelpers'
import { useZendeskSso } from '../../features/Zendesk/helpers'
import { FEATURE_FLAG_KEYS } from '../../features/OpenFeature'
import { useFlag } from '@openfeature/react-sdk'

const Login = () => {
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState<string>()
  const [showOtp, setShowOtp] = useState(false)
  const [resentCode, setResentCode] = useState(false)
  const [phoneDigits, setPhoneDigits] = useState('')

  const loginError = useReselector(selectFirstErrorMessageForKeys, [
    POST_LOGIN_KEY,
    POST_LOGIN_SSO_KEY,
  ])
  const loginSubmitting = useReselector(selectIsFetchingForKeys, [
    POST_LOGIN_KEY,
    POST_LOGIN_SSO_KEY,
  ])
  const auth = useReselector((state) => state.auth.security)
  const pageView = useAnalyticsView()
  const setUserId = useAnalyticsSetUserId()
  const track = useAnalyticsTrack()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { zendeskForm, submitForm } = useZendeskSso()

  const shouldUseJwt = pathname.endsWith('zendesk/sso')

  const maintenanceMessage = useFlag(FEATURE_FLAG_KEYS.maintenanceMessage, {
    message: null,
    pages: [] as string[],
  })

  useEffect(() => {
    // This refreshes the page which will update the index.html file.  The index.html file is not cached and will
    // reference the js bundle.  If that name has changed (hash is appended) then the user will receive updated code
    if (!window.location.hash) {
      window.location.hash = 'loaded'
      window.location.reload()
      return
    }
    pageView('login')
  }, [pageView])

  useEffect(() => {
    if (loginError)
      track('attempted login to Heard app', {
        status: 'unsuccessful',
      })
  }, [loginError, track])

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()
    setResentCode(false)
    const targetLink = localStorage.getItem('targetLink')

    const payload = {
      email: email.toLowerCase(),
      password,
      otp,
    }

    const result = shouldUseJwt
      ? await loginUserSso(payload)(dispatch)
      : await loginUser(payload)(dispatch)

    if (!result) {
      return
    }

    // If this is in result should nav user to zendesk url
    if ('jwt' in result.data) {
      track('attempted login to Heard app with zendesk sso', {
        status: 'successful',
      })

      submitForm(result.data.jwt)
    } else if (!('lastDigits' in result.data)) {
      // Successful login
      // Sets the amplitude user id
      // Note
      // We set this property within login form instead of PracticeProfile/index.tsx
      // b/c it ensures actual customers have logged in, not god-viewed users (Heard staff impersonating customers)
      setUserId(result.data.uuid)
      track('attempted login to Heard app', {
        status: 'successful',
      })

      if (targetLink) navigate(targetLink)
    } else {
      // OTP must be entered
      setShowOtp(true)
      setPhoneDigits(result.data.lastDigits)
    }
  }

  const resendCode = async () => {
    const result = await loginRegenerateOTP({
      email: email.toLowerCase(),
      password,
    })(dispatch)

    if (result) {
      setResentCode(true)
    }
  }

  const renderErrors = () =>
    loginError ? (
      <GridRowColumn>
        <Alert type="error" title={loginError} />
        <br />
      </GridRowColumn>
    ) : null

  const renderContents = () => {
    if (showOtp) {
      return (
        <>
          <GridRowColumn centered computer={6} mobile={15} tablet={12}>
            <Card fullWidth>
              {renderErrors()}
              <Grid>
                <GridRowColumn>
                  <Text as="h2" textAlign="center">
                    Enter verification code
                  </Text>
                </GridRowColumn>

                <GridRowColumn>
                  <Text textAlign="center">
                    We sent a verification code to your number on file ending
                    in: {phoneDigits}
                  </Text>
                </GridRowColumn>

                <GridRowColumn>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    shouldAutoFocus
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      width: 45,
                      border: `1px solid ${Colors.gray}`,
                      borderRadius: 8,
                      color: Colors.black,
                      padding: '10px 16px',
                      margin: '10px 3px',
                      ...Fonts.bodyMd,
                    }}
                    containerStyle={{ justifyContent: 'center' }}
                  />
                </GridRowColumn>

                <GridRowColumn>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={!otp}
                    onClick={handleSubmit}
                  >
                    Verify
                  </Button>
                </GridRowColumn>
              </Grid>
            </Card>
          </GridRowColumn>

          {resentCode ? (
            <GridRowColumn>
              <Button variant="link" disabled>
                Verification code resent!
              </Button>
            </GridRowColumn>
          ) : (
            <GridRowColumn>
              <Button variant="link" onClick={resendCode}>
                Resend your verification code
              </Button>
            </GridRowColumn>
          )}

          <GridRowColumn short>
            <Link to="/recover">Forgot password</Link>
          </GridRowColumn>

          <GridRowColumn short>
            <Text textAlign="center">
              Not receiving your verification code?
            </Text>
            <Text textAlign="center">Email us at </Text>
            <Link href="mailto: support@joinheard.com">
              support@joinheard.com
            </Link>
          </GridRowColumn>
        </>
      )
    }

    return (
      <>
        <GridRowColumn short>
          <Text textAlign="center">
            Don&apos;t have an account?{' '}
            <Link to="/signup" style={{ display: 'inline' }}>
              Sign up here.
            </Link>
          </Text>
        </GridRowColumn>
        <GridRowColumn centered computer={6} mobile={14} tablet={12}>
          <Card fullWidth>
            {renderErrors()}
            <Form onSubmit={handleSubmit} error>
              <Grid>
                {auth.updatedPassword && (
                  <GridRowColumn>
                    <Alert
                      type="info"
                      title="You've successfully changed your password! Please login with your new credentials."
                    />
                  </GridRowColumn>
                )}

                <GridRowColumn>
                  <Input
                    placeholder="Email"
                    label="Email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={setEmail}
                    fullWidth
                    required
                  />
                </GridRowColumn>

                <GridRowColumn>
                  <Input
                    placeholder="Password"
                    label="Password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={setPassword}
                    fullWidth
                    required
                  />
                </GridRowColumn>

                <GridRowColumn>
                  <Button
                    type="submit"
                    loading={loginSubmitting}
                    fullWidth
                    disabled={loginSubmitting}
                  >
                    Login
                  </Button>
                </GridRowColumn>
              </Grid>
            </Form>
          </Card>
        </GridRowColumn>
        <GridRowColumn>
          <Link to="/recover">Forgot password</Link>
        </GridRowColumn>
      </>
    )
  }

  if (auth.isAuthenticated) {
    if (shouldUseJwt) {
      return <Navigate to="/accounts/zendesk/sso" />
    }

    return <Navigate to="/dashboard" />
  }

  return (
    <Container className="finances login" fluid>
      {(maintenanceMessage?.value?.pages ?? []).includes('login') && (
        <MaintenanceBanner />
      )}
      <Grid textAlign="center" style={{ paddingTop: '3em', width: '100%' }}>
        <GridRowColumn centerContent>
          <Image
            src="https://heard-images.s3.us-west-1.amazonaws.com/heard_logo_green_2x.png"
            alt="heard logo"
            style={{ height: 20 }}
          />
        </GridRowColumn>
        <GridRowColumn>
          <Text as="h2">Welcome back!</Text>
        </GridRowColumn>
        {renderContents()}
        {zendeskForm}
      </Grid>
    </Container>
  )
}

export default Login
