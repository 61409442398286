import { createSelector } from 'reselect'
import { ReduxState } from '../../../../../../utils/typeHelpers'
import { AllEoyReviewStepState } from './allEoyReviewSteps.slice'
import {
  getAllEoyReviewSteps,
  selectEoyReviewStepForIdentifier,
} from './endOfYearReviewSteps.selector'
import {
  REVIEW_INCOME_AND_EXPENSES_INITIAL,
  SubStepIdentifiers,
  TAX_QUESTIONNAIRE_STEPS_1040_WITHOUT_DOCS,
  TAX_QUESTIONNAIRE_SECTION_STEPS_1120S_WITHOUT_DOCS,
  UPDATE_YOUR_BOOKS_COMMON,
  UPLOAD_DOCUMENTS_1040,
  UPLOAD_DOCUMENTS_1120S,
} from './stepProgress.helpers'
import {
  StepStatus,
  UserEoyReviewProgress,
} from './userEndOfYearReviewProgress.slice'
import {
  getAnnualTaxFilingForms,
  selectIsFiling1120s,
  selectIsFiling1120sForYear,
} from '../../../annualTaxFilingForms.selector'
import { TAX_ENTITY_TYPES } from '../../../../taxConstants'

export const getUserEoyReviewProgress = (state: ReduxState) =>
  state.userEoyReviewProgress

export const getUserEoyReviewProgressArray = createSelector(
  [getUserEoyReviewProgress],
  (userProgress): UserEoyReviewProgress[] => Object.values(userProgress)
)

// Gets progresses for the substeps of a tax checklist step
export const selectUserEoyReviewProgressForChecklistStep = createSelector(
  [
    getUserEoyReviewProgress,
    getAllEoyReviewSteps,
    (_: unknown, relevantIdentifiers: SubStepIdentifiers[]) =>
      relevantIdentifiers,
  ],
  (progressesById, stepsByIdentifier, relevantIdentifiers) => {
    const relevantStepIds = Object.values(stepsByIdentifier)
      .filter((step) => relevantIdentifiers.includes(step.identifier))
      .map((step) => step.id)

    return Object.values(progressesById).filter((progress) =>
      relevantStepIds.includes(progress.endOfYearReviewStepId)
    )
  }
)

export const selectUserEoyReviewProgressStatusForChecklistSteps =
  createSelector(
    [selectUserEoyReviewProgressForChecklistStep],
    (progressesForStep) => {
      if (
        progressesForStep.length === 0 ||
        progressesForStep.every((p) => p.completedAt === null)
      ) {
        //progresses would have been created if they started the step
        return StepStatus.notStarted
      } else if (
        progressesForStep.every((progress) => Boolean(progress.completedAt))
      ) {
        return StepStatus.completed
      }
      return StepStatus.inProgress
    }
  )

export const selectUserEoyReviewProgressForSubstepIdentifier = createSelector(
  getUserEoyReviewProgress,
  selectEoyReviewStepForIdentifier,
  (progressesById, subStep) => {
    return Object.values(progressesById).find(
      (progress) => progress.endOfYearReviewStepId === subStep?.id
    )
  }
)

const progressCompleteForStep = (
  step: SubStepIdentifiers,
  allSteps: AllEoyReviewStepState,
  progresses: UserEoyReviewProgress[]
) =>
  Boolean(
    progresses.find(
      (progress) =>
        allSteps[step]?.id === progress.endOfYearReviewStepId &&
        progress.completedAt
    )
  )

const getBooksCompleteSteps = (isFiling1120s: boolean, finalCheck: boolean) => {
  const booksCompleteSteps = [
    SubStepIdentifiers.reviewIncomeInitial,
    SubStepIdentifiers.reviewExpensesInitial,
    SubStepIdentifiers.clarifyTransactions,
    SubStepIdentifiers.categorizeOtherExpenses,
    SubStepIdentifiers.categorizeOtherIncome,
    SubStepIdentifiers.addBusinessIncomeTransactions,
    SubStepIdentifiers.addBusinessExpenseTransactions,
  ]

  if (isFiling1120s) {
    booksCompleteSteps.push(
      SubStepIdentifiers.confirmOwnersInvestmentTransactions,
      SubStepIdentifiers.confirmOwnersDistributionTransactions
    )
  }

  if (finalCheck) {
    booksCompleteSteps.push(
      SubStepIdentifiers.reviewIncomeFinal,
      SubStepIdentifiers.reviewExpensesFinal
    )
  }
  return booksCompleteSteps
}

/**
 * Returns all the steps required in the 'Close Your Books' section of the tax checklist
 */
export const selectReviewBooksSteps = createSelector(
  selectIsFiling1120s,
  (_: unknown, final?: boolean) => final,
  (isFiling1120s, finalCheck = false) =>
    getBooksCompleteSteps(isFiling1120s, finalCheck)
)
/**
 * Returns true if all the steps in the 'Close Your Books' section of the tax checklist are complete
 */
export const selectReviewBooksCompleted = createSelector(
  getUserEoyReviewProgressArray,
  getAllEoyReviewSteps,
  selectReviewBooksSteps,
  (userProgresses, allSteps, closeBooksSteps) => {
    return closeBooksSteps.every((step) =>
      progressCompleteForStep(step, allSteps, userProgresses)
    )
  }
)

export const selectReviewBooksInitialCompletedForYear = createSelector(
  getUserEoyReviewProgressArray,
  getAllEoyReviewSteps,
  selectIsFiling1120sForYear,
  (userProgresses, allSteps, isFiling1120s) => {
    return getBooksCompleteSteps(isFiling1120s, false).every((step) =>
      progressCompleteForStep(step, allSteps, userProgresses)
    )
  }
)

export const selectCloseBooksSectionStepsCompleteAndTotalCount = createSelector(
  getUserEoyReviewProgressArray,
  getAllEoyReviewSteps,
  selectIsFiling1120sForYear,
  (userProgresses, allSteps, isFiling1120s) => {
    const updateBooksSteps = isFiling1120s
      ? [
          ...UPDATE_YOUR_BOOKS_COMMON,
          SubStepIdentifiers.confirmOwnersInvestmentTransactions,
          SubStepIdentifiers.confirmOwnersDistributionTransactions,
        ]
      : UPDATE_YOUR_BOOKS_COMMON
    const closeBooksSectionSteps = [
      [...REVIEW_INCOME_AND_EXPENSES_INITIAL],
      [...updateBooksSteps],
    ]

    let completeCount = 0
    closeBooksSectionSteps.forEach((substeps) => {
      if (
        substeps.every((step) =>
          progressCompleteForStep(step, allSteps, userProgresses)
        )
      ) {
        completeCount++
      }
    })

    return { completeCount, totalCount: closeBooksSectionSteps.length }
  }
)

export const selectQuarterlyPaymentsStepComplete = createSelector(
  getUserEoyReviewProgressArray,
  getAllEoyReviewSteps,
  (userProgresses, allSteps) => {
    return progressCompleteForStep(
      SubStepIdentifiers.addMissingQTEPayments,
      allSteps,
      userProgresses
    )
  }
)

export const selectAllTQTasksExceptUploadDocumentsAreCompleteForYear =
  createSelector(
    getUserEoyReviewProgressArray,
    getAllEoyReviewSteps,
    getAnnualTaxFilingForms,
    (_: unknown, _year: string, formId: number | string) => formId,
    (userProgresses, allSteps, forms, formId) => {
      const is1120s =
        forms[formId]?.formType.name === TAX_ENTITY_TYPES.form_1120_s
      const allTasksExceptUploadDocuments = is1120s
        ? TAX_QUESTIONNAIRE_SECTION_STEPS_1120S_WITHOUT_DOCS
        : TAX_QUESTIONNAIRE_STEPS_1040_WITHOUT_DOCS
      return allTasksExceptUploadDocuments.every((substeps) =>
        substeps.every((step) =>
          progressCompleteForStep(step, allSteps, userProgresses)
        )
      )
    }
  )

export const selectDocumentsUploadStepCompleteForYear = createSelector(
  getUserEoyReviewProgressArray,
  getAllEoyReviewSteps,
  getAnnualTaxFilingForms,
  (_: unknown, _year: string, formId: number | string) => formId,
  (userProgresses, allSteps, forms, formId) => {
    const is1120s =
      forms[formId]?.formType.name === TAX_ENTITY_TYPES.form_1120_s
    const documentsUploadSteps = is1120s
      ? UPLOAD_DOCUMENTS_1120S
      : UPLOAD_DOCUMENTS_1040
    return documentsUploadSteps.every((step) =>
      progressCompleteForStep(step, allSteps, userProgresses)
    )
  }
)
