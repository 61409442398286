import { useMemo } from 'react'
import moment from 'moment'
import { Grid, Image } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Text,
  Icon,
  GridRowColumn,
  Button,
  Card,
} from '../../../../../components/BaseComponents'
import { useReselector } from '../../../../../utils/sharedHooks'
import {
  selectActiveQTEDetailsAsDates,
  selectActiveQuarterlyTaxEstimateDetails,
} from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import DeadlineLabel from '../DeadlineLabel'
import {
  DATE_FORMATS,
  DATE_FORMATS_LUXON,
} from '../../../../../utils/dateHelpers'
import { UserTaxEstimate } from '../../userTaxEstimates.slice'
import SafeHarborPaymentCard from './SafeHarborPaymentCard'
import StateSafeHarborCard from './StateSafeHarborCard'
import { useNavigate } from 'react-router-dom'
import { WhatIsSafeHarborPopup } from './helpers'

const FederalSafeHarborCard = () => {
  const navigate = useNavigate()

  const activeQuarterDetails = useReselector(selectActiveQTEDetailsAsDates)

  if (!activeQuarterDetails) {
    return null
  }

  return (
    <Card type="section" backgroundColor="stone40">
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={4}>
            <Image
              style={{ height: 150, width: 150 }}
              src="https://heard-images.s3.amazonaws.com/assets/writing_check.svg"
            />
          </Grid.Column>
          <Grid.Column width={12}>
            <Grid>
              <GridRowColumn>
                <Text as="eyebrow" color="mediumGray">
                  important note
                </Text>
              </GridRowColumn>
              <GridRowColumn stretched>
                <Text as="h2">
                  Paying Q{activeQuarterDetails.taxQuarter} Federal Quarterly
                  Taxes
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="bodyLg" color="darkGray">
                  Since you signed up after{' '}
                  {activeQuarterDetails.withOffset.newUserCutOff.toFormat(
                    DATE_FORMATS_LUXON.DISPLAY_LONG
                  )}
                  , we won&apos;t be able to provide a calculated quarterly tax
                  estimate using your practice income this quarter. This
                  quarter, you can use our safe harbor estimate calculator to
                  determine an estimate that will help minimize federal
                  underpayment penalties.
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <WhatIsSafeHarborPopup />
              </GridRowColumn>
              <GridRowColumn>
                <Button
                  onClick={() => {
                    navigate('/taxes/quarterly/calculate-safe-harbor')
                  }}
                >
                  Calculate safe harbor
                </Button>
              </GridRowColumn>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}
const UserJoinedTooLateV2 = ({
  activeQuarterText,
  federalTaxEstimate,
}: {
  activeQuarterText: string
  federalTaxEstimate: UserTaxEstimate | null
}) => {
  const activeQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )

  const federalContent = useMemo(() => {
    if (!activeQuarterDetails || !federalTaxEstimate) {
      return null
    }
    if (
      federalTaxEstimate.estimateInCents >= 0 &&
      federalTaxEstimate.safeHarborType
    ) {
      return <SafeHarborPaymentCard federalTaxEstimate={federalTaxEstimate} />
    } else {
      return <FederalSafeHarborCard />
    }
  }, [federalTaxEstimate, activeQuarterDetails])

  return (
    <Grid>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={15}>
          <Text as="h2">{activeQuarterText} Estimates</Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ padding: '0' }}>
        <Grid.Column>
          <DeadlineLabel
            icon={
              <Icon
                style={{ paddingRight: 8 }}
                icon={regular('square-dollar')}
              />
            }
            text={'IRS payment due'}
            prominentText={moment
              .utc(activeQuarterDetails?.irsPaymentDueAt)
              .format(DATE_FORMATS.DISPLAY_LONG)}
          />
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn>{federalContent}</GridRowColumn>
      <GridRowColumn>
        <StateSafeHarborCard />
      </GridRowColumn>
    </Grid>
  )
}

export default UserJoinedTooLateV2
