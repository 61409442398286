import { useMemo } from 'react'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  selectExpectedAnnualTaxFormNeeds,
  selectLateFilingStatusType,
  selectShowLateFilingAlert,
  LateFilingStatus,
  selectRelevantLateFilingDeadline,
} from '../annualTaxFilings.selector'
import { Alert, GridRowColumn } from '../../../../components/BaseComponents'

export const LateFilingAlert = () => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const formNeeds = useReselector(selectExpectedAnnualTaxFormNeeds, taxYear)
  const lateFilingStatusType = useReselector(selectLateFilingStatusType)

  const deadline = useReselector(selectRelevantLateFilingDeadline)

  const showAlert = useReselector(selectShowLateFilingAlert)

  const headerText = `${taxYear} Tax Deadline: ${deadline?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}`

  const bodyText = useMemo(() => {
    switch (lateFilingStatusType.status) {
      case LateFilingStatus.cybNeeded:
        if (formNeeds.length === 1) {
          return `Your books must be closed by the deadline, or Heard will not be able to prepare your ${taxYear} tax return.`
        } else {
          return `Your books must be closed by the deadline, or Heard will not be able to prepare your ${taxYear} Business Tax (Form 1120-S) or Personal Tax (Form 1040) returns.`
        }
      case LateFilingStatus.taxChecklistNeeded:
        if (formNeeds.length === 1) {
          // same copy for single form filers
          return `Your Tax Checklist must be completed by the deadline, or Heard will not be able to prepare your ${taxYear} tax return.`
        } else if (lateFilingStatusType.applicableTo.length === 2) {
          // if they're a two form filer and haven't completed their 1120-S
          return `You must complete your Business Tax Checklist, or Heard will not be able to prepare your ${taxYear} Business Tax (Form 1120-S) or Personal Tax (Form 1040) returns.`
        } else {
          // if they're a two form filer and have completed their 1120-S but not their 1040
          return `You must complete your Personal Tax Checklist, or Heard will not be able to prepare your ${taxYear} Personal Tax (Form 1040) return.`
        }
      case LateFilingStatus.tskMissed:
      case LateFilingStatus.cybMissed:
      case LateFilingStatus.taxChecklistMissed:
      case LateFilingStatus.notRelevant:
        return null
      default:
        return lateFilingStatusType.status satisfies never
    }
  }, [formNeeds, lateFilingStatusType, taxYear])

  if (
    lateFilingStatusType.status === LateFilingStatus.notRelevant ||
    !showAlert ||
    !bodyText
  ) {
    return null
  }

  return (
    <GridRowColumn>
      <Alert title={headerText} type="warn">
        {bodyText}
      </Alert>
    </GridRowColumn>
  )
}
