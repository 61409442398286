import { useCallback, useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import {
  Accordion,
  Alert,
  Button,
  Checkbox,
  GridRowColumn,
  Icon,
  Text,
} from '../../../components/BaseComponents'
import FileUploadModal from '../../../components/FileUpload/FileUploadModal'
import { UploadDocumentType } from '../../../constants/businessConstants'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Colors } from '../../../styles/theme'
import Tooltip from '../../Taxes/QuarterlyTaxEstimates/components/Tooltip'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import { fetchPlaidItemsIfNeeded } from '../../../actions/plaidItemActions'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { PlaidItem } from '../../../reducers/finances/plaidItemReducer'
import { UnsupportedInstitutionCard } from './UnsupportedInstitutionCard'
import { EditableStatementTable } from '../EditableStatementTable'
import { useReselector } from '../../../utils/sharedHooks'
import { selectUserStatements } from '../../UserDocuments/userDocuments.selector'
import { useSearchParams } from 'react-router-dom'
import { useAnalyticsTrack } from '../../Amplitude'

export const NoLBAInstitutionCard = ({
  filteredPlaidItems,
}: {
  filteredPlaidItems: PlaidItem[]
}) => {
  const dispatch = useAppDispatch()
  const defaultSkipState =
    localStorage.getItem('skipUploadStatements') === 'true' || false
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false)
  const [checked, setChecked] = useState(defaultSkipState)
  const [searchParams, setSearchParams] = useSearchParams()
  const track = useAnalyticsTrack()

  const currentYear = new Date().getFullYear()
  const statements = useReselector(selectUserStatements)

  const setSkipQueryParam = useCallback(() => {
    searchParams.set('skip', checked.toString())
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams, checked])

  useEffect(() => {
    setSkipQueryParam()
  }, [checked, setSkipQueryParam])

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchFinancialAccountsIfNeeded())
      await dispatch(fetchPlaidItemsIfNeeded())
    }

    fetch()
  }, [dispatch])

  const handleSkip = () => {
    setChecked((prevChecked) => !prevChecked)
    localStorage.setItem('skipUploadStatements', (!checked).toString())
  }

  useEffect(() => {
    if (checked) {
      track(
        'checked I just created my business bank account and dont have any statements yet'
      )
    }
  }, [checked, track])

  return (
    <Grid>
      <GridRowColumn>
        <Text as="h2">Upload monthly bank statements</Text>
      </GridRowColumn>
      <Text>
        Make sure to upload monthly bank statements for all accounts listed
        below. Why these accounts?
        <Tooltip
          popup={{
            title: 'Why these accounts?',
            body: 'These accounts don’t support limited bank access, so you’ll still need to upload bank statements every month.',
          }}
        />
      </Text>
      <GridRowColumn>
        <Grid
          columns={4}
          style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}
        >
          {filteredPlaidItems.map((item) => (
            <Grid.Column
              key={item.id}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <UnsupportedInstitutionCard key={item.id} institution={item} />
            </Grid.Column>
          ))}
        </Grid>
      </GridRowColumn>
      <GridRowColumn>
        <Text>1. Log into each account listed above.</Text>
        <Text>
          2. Download all monthly statements for each month of {currentYear}.
        </Text>
        <Text>3. Upload these statements (JPG, PNG, PDF) here.</Text>
      </GridRowColumn>
      <GridRowColumn>
        <Accordion
          title="Why do you need my bank statements?"
          content={
            <>
              <Text>
                We use your statements to double-check your books each month.
                This is a necessary step to provide accurate quarterly tax
                estimates and insights for your practice.
              </Text>
              <br />
              <Text as="h3">But I just connected my bank account?</Text>
              <Text>
                Connecting your account doesn&apos;t automatically grant Heard
                access to your bank statements. Common reasons we may not have
                access:
                <ul>
                  <li>
                    Your bank doesn&apos;t allow Heard access to your bank
                    statements
                  </li>
                  <li>
                    You declined access to your statements when connecting your
                    account
                  </li>
                </ul>
              </Text>
              <Text>
                Why doesn’t limited bank access work for these accounts?
              </Text>
              <br />
              <Text as="h3">
                Limited bank access is only compatible with certain banks. We’ll
                take care of statements for compatible accounts, but you’ll need
                to manually upload them for the rest.
              </Text>
            </>
          }
          variant="text"
        />
      </GridRowColumn>
      {Boolean(statements?.length) && (
        <EditableStatementTable documents={statements} />
      )}
      {!checked && (
        <GridRowColumn>
          <Button onClick={() => setUploadFileModalOpen(true)}>
            <Icon icon={regular('upload')} style={{ marginRight: 10 }} />
            Upload
          </Button>
        </GridRowColumn>
      )}
      <FileUploadModal
        documentType={UploadDocumentType.STATEMENT}
        open={uploadFileModalOpen}
        close={() => setUploadFileModalOpen(false)}
        userFacing
        useFilteredAccountOptions
      />
      <GridRowColumn>
        <Checkbox
          checked={checked}
          variant="default"
          onClick={handleSkip}
          label="I just created my business bank account and don't have any statements yet."
        />
      </GridRowColumn>
      {checked && (
        <GridRowColumn
          style={{
            backgroundColor: Colors.stone,
            paddingTop: 0,
          }}
        >
          <Alert type="info">
            No problem. We&apos;ll ask you to upload your statements later.
          </Alert>
        </GridRowColumn>
      )}
    </Grid>
  )
}
