import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { useReselector } from '../../../../../utils/sharedHooks'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { selectFederalEstimateByTaxQuarter } from '../../userTaxEstimates.selector'
import {
  CALCULATE_SAFE_HARBOR_STEP,
  calculateSafeHarbor,
  useCalculateSafeHarborSteps,
} from './helpers'
import { getFinancialProfile } from '../../../../../selectors/user.selectors'
import {
  SafeHarborType,
  UPDATE_USER_TAX_ESTIMATES_KEY,
  updateUserTaxEstimates,
} from '../../userTaxEstimates.slice'
import {
  dollarsToCents,
  dollarsToCurrency,
  formatCurrency,
} from '../../../../../utils/currencyHelpers'
import {
  Alert,
  Card,
  FormikInput,
  getFieldName,
  GridRowColumn,
  Icon,
  makeNumberSchema,
  Text,
} from '../../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../../components/BaseComponents/Grid'
import { getFetchError } from '../../../../../reducers/fetch'
import TaxProfileAccordions, {
  useTaxProfileAccordionCopy,
} from '../../../TaxesProfile/FormFlow/TaxProfileAccordions'
import CalculateSafeHarborFooter from './CalculateSafeHarborFooter'
import ImageOffset from '../../../../../components/shared/ImageOffset'
import { QuarterlyTaxEstimateDetail } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'

const TotalTaxesAndIncome = ({
  details,
}: {
  details?: QuarterlyTaxEstimateDetail | null
}) => {
  const dispatch = useAppDispatch()

  const { goToPreviousStep, goToNextStep } = useCalculateSafeHarborSteps(
    CALCULATE_SAFE_HARBOR_STEP.totalTaxesAndIncome
  )

  const federalTaxEstimate = useReselector(
    selectFederalEstimateByTaxQuarter,
    `${details?.taxYear}-${details?.taxQuarter}`
  )

  const fp = useReselector(getFinancialProfile)

  const error = useReselector(getFetchError, UPDATE_USER_TAX_ESTIMATES_KEY)

  // declared early so it can be used in onSubmit
  let safeHarborAmount: number | null = null

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      adjustedGrossIncome: null as number | null,
      totalFederalTaxes: null as number | null,
    },
    onSubmit: async () => {
      if (!federalTaxEstimate) {
        return
      }

      const res = await dispatch(
        updateUserTaxEstimates(federalTaxEstimate?.id, {
          estimateInCents: dollarsToCents(safeHarborAmount),
          safeHarborType: SafeHarborType.calculated,
        })
      )

      if (res) {
        goToNextStep()
      }
    },
  })

  const { values, isSubmitting, isValid, submitForm } = formik

  safeHarborAmount = calculateSafeHarbor({
    adjustedGrossIncomeInDollars: values.adjustedGrossIncome,
    totalFederalTaxesInDollars: values.totalFederalTaxes,
    filingStatus: fp?.filingStatus,
  })

  const previousYear = Number(details?.taxYear) - 1

  const federalTaxesSchema = makeNumberSchema({
    allowedDecimals: 2,
    required: false,
  }).test(
    'federalTaxesValidation',
    'Total federal taxes cannot exceed your adjusted gross income. Please double-check the numbers you’ve entered.',
    (federalTaxes) => {
      return (
        !federalTaxes ||
        !values?.adjustedGrossIncome ||
        dollarsToCurrency(values.adjustedGrossIncome).value >=
          dollarsToCurrency(federalTaxes).value
      )
    }
  )

  const faqCopy = useTaxProfileAccordionCopy()

  return (
    <FormikProvider value={formik}>
      <Grid>
        <ImageOffset
          imageLink="https://heard-images.s3.amazonaws.com/assets/1040_adjusted_gross_income.svg"
          width={523}
          height={71}
          backgroundPosition="0 -210px"
          linkText="1040 Page 1, Adjusted Gross Income"
        />
        <ImageOffset
          imageLink="https://heard-images.s3.amazonaws.com/assets/1040_total_taxes.svg"
          width={523}
          height={136}
          linkText="1040 Page 2, Total Taxes"
        />
        <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
          <Text as="display2" textAlign="center">
            How much did you pay in <b>total federal taxes</b> for{' '}
            {previousYear}?
          </Text>
        </GridRowColumn>
        {error && (
          <GridRowColumn>
            <Alert type="error">{error.message}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
          <FormikInput
            name={getFieldName<typeof values>('adjustedGrossIncome')}
            required
            fullWidth
            componentType="currency"
            schema={makeNumberSchema({
              allowedDecimals: 2,
              greaterThanZero: true,
            })}
            label="Adjusted gross income"
            description="You can find this amount on page 1, line 11 of your 1040 Personal Tax return."
            placeholder="$"
          />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
          <FormikInput
            name={getFieldName<typeof values>('totalFederalTaxes')}
            fullWidth
            componentType="currency"
            schema={federalTaxesSchema}
            label={`Total ${previousYear} federal taxes`}
            description="You can find this amount on page 2, line 24 of your 1040 Individual Tax return."
            placeholder="$"
          />
        </GridRowColumn>
        {values.totalFederalTaxes && Number(values.totalFederalTaxes) === 0 && (
          <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
            <Alert
              title="Are you sure?"
              type="warn"
              customIcon={<Icon icon={regular('stars')} />}
            >
              It looks like you entered $0 for your {previousYear} federal
              taxes, which is uncommon. Check your return to make sure you have
              the correct number.
            </Alert>
          </GridRowColumn>
        )}
        <GridRowColumn {...makeGridConfig([8, 12, 16], true)}>
          <Card backgroundColor="stone40">
            <Grid>
              <GridRowColumn short>
                <Text as="eyebrow" color="darkGray">
                  federal
                </Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text as="display2">
                  {safeHarborAmount !== null
                    ? formatCurrency(safeHarborAmount, {
                        hideDecimalsIfZero: true,
                      })
                    : '$--'}
                </Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text as="bodySm" color="darkGray">
                  Safe harbor estimate
                </Text>
              </GridRowColumn>
            </Grid>
          </Card>
        </GridRowColumn>
        {safeHarborAmount !== null && (
          <GridRowColumn {...makeGridConfig([10, 12, 16], true)}>
            <Text color="forest">
              This is your safe harbor amount for each quarter, inclusive of
              other federal taxes withheld. Paying this amount each quarter will
              reduce your risk of a federal underpayment penalty at the end of
              the year.
            </Text>
          </GridRowColumn>
        )}
        <Grid.Row />
        <Grid.Row />
        <TaxProfileAccordions
          faqs={[
            faqCopy.whyNeedKnowAmountTaxes,
            faqCopy.whatIsSafeHarbor,
            faqCopy.willPaySafeHarborNoTaxes,
            faqCopy.whyEstimateHigh,
            faqCopy.taxesWithheldW2,
          ]}
        />

        <CalculateSafeHarborFooter
          submitOrContinue={submitForm}
          goToPreviousStep={goToPreviousStep}
          continueDisabled={!isValid || isSubmitting}
          loading={isSubmitting}
          nextText="Save"
        />
      </Grid>
    </FormikProvider>
  )
}

export default TotalTaxesAndIncome
