import { useFlag } from '@openfeature/react-sdk'
import Markdown from 'markdown-to-jsx'

import { Alert } from '../BaseComponents'
import { FEATURE_FLAG_KEYS } from '../../features/OpenFeature'

const MaintenanceBanner = () => {
  const maintenanceMessage = useFlag(FEATURE_FLAG_KEYS.maintenanceMessage, {
    message: null,
  })

  if (!maintenanceMessage.value.message) {
    return null
  }

  return (
    <Alert style={{ borderRadius: 0 }} type="announcement">
      <Markdown>{maintenanceMessage.value.message}</Markdown>
    </Alert>
  )
}

export default MaintenanceBanner
