import { Grid } from 'semantic-ui-react'
import {
  Alert,
  Button,
  Card,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../../components/BaseComponents'
import DeadlineLabel from '../DeadlineLabel'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectQuarterDetailsForQuarterBeforeActiveQuarter } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import moment from 'moment'
import { DATE_FORMATS } from '../../../../../utils/dateHelpers'
import { useMemo } from 'react'
import SafeHarborPaymentCard from './SafeHarborPaymentCard'
import { selectFederalEstimateByTaxQuarter } from '../../userTaxEstimates.selector'
import { WhatIsSafeHarborPopup } from './helpers'
import { useNavigate } from 'react-router-dom'
import StateSafeHarborCard from './StateSafeHarborCard'

const LastQuarterFederalSafeHarborCard = () => {
  const navigate = useNavigate()

  const lastQuarterDetails = useReselector(
    selectQuarterDetailsForQuarterBeforeActiveQuarter
  )

  return (
    <Card type="section" backgroundColor="natural">
      <Grid>
        <GridRowColumn>
          <Text as="eyebrow" color="mediumGray">
            important note
          </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text as="h2">
            Paying Q{lastQuarterDetails?.taxQuarter} Federal Quarterly Taxes
            with Safe Harbor
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text as="bodySm">
            The payment date for this quarter has passed. You can still use safe
            harbor to get an estimate that will help minimize federal
            underpayment penalties.
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <WhatIsSafeHarborPopup />
        </GridRowColumn>
        <GridRowColumn>
          <Alert
            title="Tip: It’s always better to pay something than nothing."
            customIcon={<Icon icon={solid('stars')} />}
          >
            Minimize how much you&apos;ll owe in interest by paying as soon as
            you can, even if it&apos;s not the full amount.
          </Alert>
        </GridRowColumn>
        <GridRowColumn>
          <Button
            onClick={() => {
              navigate('/taxes/quarterly/calculate-safe-harbor')
            }}
          >
            Calculate safe harbor
          </Button>
        </GridRowColumn>
      </Grid>
    </Card>
  )
}
const LastQuarterSafeHarborFlow = () => {
  const lastQuarterDetails = useReselector(
    selectQuarterDetailsForQuarterBeforeActiveQuarter
  )

  const federalTaxEstimate = useReselector(
    selectFederalEstimateByTaxQuarter,
    `${lastQuarterDetails?.taxYear}-${lastQuarterDetails?.taxQuarter}`
  )

  const federalContent = useMemo(() => {
    if (!lastQuarterDetails || !federalTaxEstimate) {
      return null
    }
    if (
      federalTaxEstimate.estimateInCents >= 0 &&
      federalTaxEstimate.safeHarborType
    ) {
      return <SafeHarborPaymentCard federalTaxEstimate={federalTaxEstimate} />
    } else {
      return <LastQuarterFederalSafeHarborCard />
    }
  }, [federalTaxEstimate, lastQuarterDetails])

  return (
    <>
      <GridRowColumn>
        <Text as="h2">Last Quarter&apos;s Estimates</Text>
      </GridRowColumn>
      <GridRowColumn short>
        <DeadlineLabel
          icon={
            <Icon style={{ paddingRight: 8 }} icon={regular('square-dollar')} />
          }
          text={'IRS payment due'}
          prominentText={moment
            .utc(lastQuarterDetails?.irsPaymentDueAt)
            .format(DATE_FORMATS.DISPLAY_LONG)}
        />
      </GridRowColumn>
      <GridRowColumn>{federalContent}</GridRowColumn>
      <GridRowColumn>
        <StateSafeHarborCard forLastQuarter />
      </GridRowColumn>
    </>
  )
}

export default LastQuarterSafeHarborFlow
