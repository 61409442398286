import { Card, GridRowColumn, Text } from '../../components/BaseComponents'
import { Divider, Grid } from 'semantic-ui-react'
import { useReselector } from '../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import DetailedFormStatusItem from '../Taxes/AnnualTaxes/Overview/DetailedFormStatusItem'
import {
  select1040FormForYear,
  select1120sFormForYear,
} from '../Taxes/AnnualTaxes/annualTaxFilingForms.selector'
import {
  getAnnualTaxFilingForYearSelector,
  LateFilingStatus,
  selectFileExtensionStatusForYear,
  selectLateFilingStatusType,
  selectShowFileExtensionForYear,
} from '../Taxes/AnnualTaxes/annualTaxFilings.selector'
import { useEffect } from 'react'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from '../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import {
  FETCH_TAX_FILINGS_KEY,
  fetchAnnualTaxFilingsIfNeeded,
} from '../Taxes/AnnualTaxes/annualTaxFilings.slice'
import { selectIsFetchingForKeys } from '../../reducers/fetch'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { AnnualTaxFilingStepStatus } from '../Taxes/AnnualTaxes/constants'
import { useAppDispatch } from '../../utils/typeHelpers'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'

const AnnualTaxesStatusCard = () => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const form1120s = useReselector(select1120sFormForYear, taxYear)
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const taxFiling = useReselector(getAnnualTaxFilingForYearSelector, taxYear)
  const tskComplete = taxFiling?.pretaxSurveySubmittedAt !== null
  const needsExtension = useReselector(selectShowFileExtensionForYear, taxYear)
  const filingExtensionStatus = useReselector(
    selectFileExtensionStatusForYear,
    taxYear
  )

  const isFetching = useReselector(selectIsFetchingForKeys, [
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
    FETCH_TAX_FILINGS_KEY,
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  ])

  //for users who we manually want to allow to ignore the deadlines and continue with the tax filing process
  const skipDeadline = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.allowAnnualTaxAdHocDeadlineExtension,
    false
  )

  const { status } = useReselector(selectLateFilingStatusType)

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded)
  }, [dispatch])

  if (
    needsExtension &&
    filingExtensionStatus !== AnnualTaxFilingStepStatus.completeLocked
  ) {
    return null
  } else if (isFetching || (tskComplete && taxFiling?.optedOutAt !== null)) {
    return null
  } else if (
    !skipDeadline &&
    [
      LateFilingStatus.tskMissed,
      LateFilingStatus.cybMissed,
      LateFilingStatus.taxChecklistMissed,
    ].includes(status)
  ) {
    return null
  }
  return (
    <Card backgroundColor="stone40">
      <Grid>
        <GridRowColumn>
          <Text as="eyebrow" style={{ marginBottom: 5 }}>
            Annual Taxes
          </Text>
          <Text as="h2">Tax Return Status</Text>
        </GridRowColumn>
        {form1120s && (
          <GridRowColumn>
            <DetailedFormStatusItem form={form1120s} forDashboard />
          </GridRowColumn>
        )}
        {form1120s && form1040 && <Divider style={{ margin: '0px 14px' }} />}
        {form1040 && (
          <GridRowColumn>
            <DetailedFormStatusItem form={form1040} forDashboard />
          </GridRowColumn>
        )}
      </Grid>
    </Card>
  )
}

export default AnnualTaxesStatusCard
