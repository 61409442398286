import { useMemo } from 'react'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  LateFilingStatus,
  selectExpectedAnnualTaxFormNeedsForCurrentTaxYear,
  selectFilingStatusFor1040,
  selectFilingStatusFor1120s,
  selectLateFilingStatusType,
  selectRelevantLateFilingDeadline,
  selectShowNeedContractorFilingByYear,
} from '../annualTaxFilings.selector'
import { TaxFormType } from '../Questionnaires/constants'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import { Grid, Image } from 'semantic-ui-react'
import {
  Accordion,
  Card,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import {
  select1040FormForYear,
  select1120sFormForYear,
} from '../annualTaxFilingForms.selector'
import { AnnualTaxFilingStepStatus } from '../constants'
import { FilingStatusByForm } from './DetailedFormStatusItem'
import { ContractorList } from '../Ten99Filing/ContractorList'
import ExtensionSurveyCard from '../ExtensionSurvey/ExtensionSurveyCard'
import AnnualTaxImportantDateCard from './AnnualTaxImportantDateCard'
import AnnualTaxFaq from './AnnualTaxFaq'
import {
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../../../utils/deviceWidthHelpers'

const UserMissedDeadlinePanel = () => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)
  const formNeeds = useReselector(
    selectExpectedAnnualTaxFormNeedsForCurrentTaxYear
  )
  const { status, applicableTo } = useReselector(selectLateFilingStatusType)

  const deadline = useReselector(selectRelevantLateFilingDeadline)

  const headerText = `${taxYear} Tax preparation unavailable`

  const bodyText = useMemo(() => {
    switch (status) {
      case LateFilingStatus.tskMissed: {
        const formType =
          applicableTo.length > 1
            ? ' '
            : applicableTo.includes(TaxFormType.form1120s)
              ? ' business '
              : ' personal '
        return `Since you did not opt into ${taxYear} tax preparation, Heard will not be able to prepare your${formType}taxes.`
      }
      case LateFilingStatus.cybMissed: {
        const formText =
          applicableTo.length > 1
            ? 'Business Tax (Form 1120-S) or Personal Tax (Form 1040) filings.'
            : applicableTo.includes(TaxFormType.form1120s)
              ? 'Business Tax (Form 1120-S) filing.'
              : 'Personal Tax (Form 1040) filing.'
        return `Since your books were not closed by the deadline of ${deadline?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}, Heard will not be able to prepare your ${taxYear}${' '}${formText}`
      }
      case LateFilingStatus.taxChecklistMissed:
        if (formNeeds.length === 1) {
          return applicableTo.includes(TaxFormType.form1120s)
            ? `Since your Business Tax Checklist was not completed by the deadline of ${deadline?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}, Heard will not be able to prepare your ${taxYear} Business Tax (Form 1120-S) filing.`
            : `Since your Personal Tax Checklist was not completed by the deadline of ${deadline?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}, Heard will not be able to prepare your ${taxYear} Personal Tax (Form 1040) filing.`
        } else if (applicableTo.length === 2) {
          // if they're a two form filer and haven't completed their 1120-S
          return `Since your Business Tax Checklist was not completed by the deadline of ${deadline?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}, Heard will not be able to prepare your ${taxYear} Business Tax (Form 1120-S) or Personal Tax (Form 1040) filings.`
        } else {
          // if they're a two form filer and have completed their 1120-S but not their 1040
          return `Since your Personal Tax Checklist was not completed by the deadline of ${deadline?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}, Heard will not be able to prepare your ${taxYear} Personal Tax (Form 1040) filing.`
        }
      case LateFilingStatus.notRelevant:
      case LateFilingStatus.cybNeeded:
      case LateFilingStatus.taxChecklistNeeded:
        return null
      default:
        return status satisfies never
    }
  }, [status, taxYear, applicableTo, formNeeds, deadline])

  const whyText =
    status === LateFilingStatus.cybMissed
      ? "Heard's tax experience is different from most as we require completion of bookkeeping through Heard in order to prepare accurate tax returns. Unlike services which focus on tax preparation and have you enter final values for income, we instead produce accurate books and then use these to prepare your return. Since bookkeeping wasn't complete by the required deadline, there is no longer adequate time for us to provide you with tax preparation through Heard."
      : `There is no longer adequate time for us to provide you with tax preparation through Heard for the ${taxYear} tax year.`

  const form1120s = useReselector(select1120sFormForYear, taxYear)
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const form1120sStatus = useReselector(selectFilingStatusFor1120s, taxYear)
  const form1120sCompleted =
    form1120sStatus === AnnualTaxFilingStepStatus.completeLocked
  const form1040Status = useReselector(selectFilingStatusFor1040, taxYear)
  const form1040Completed =
    form1040Status === AnnualTaxFilingStepStatus.completeLocked

  const showContractorFiling = useReselector(
    selectShowNeedContractorFilingByYear,
    taxYear
  )

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={isMobileOrTablet ? 16 : 10}>
          <Grid>
            <GridRowColumn>
              <Card type="section" backgroundColor="stone40">
                <Grid>
                  <Grid.Column width={2}>
                    <Image
                      style={{ height: 80, width: 80 }}
                      src="https://heard-images.s3.amazonaws.com/assets/calendar-warning.svg"
                      centered
                    />
                  </Grid.Column>
                  <Grid.Column width={14}>
                    <Grid>
                      <GridRowColumn>
                        <Text as="h2">{headerText}</Text>
                      </GridRowColumn>
                      <GridRowColumn>
                        <Text as="bodySm">{bodyText}</Text>
                      </GridRowColumn>
                      <GridRowColumn>
                        <Accordion
                          variant="text"
                          title="Why?"
                          titleStyle={{
                            textDecoration: 'underline',
                            textUnderlineOffset: 3,
                          }}
                          content={
                            <Grid>
                              <GridRowColumn>
                                <Text as="bodySm">{whyText}</Text>
                              </GridRowColumn>
                              <GridRowColumn>
                                <Text as="bodySm">
                                  For that reason, you&apos;ll need to work with
                                  a more traditional tax preparer or CPA to
                                  prepare your taxes as we&apos;re no longer
                                  able to prepare your taxes ahead of the
                                  deadline.
                                </Text>
                              </GridRowColumn>
                            </Grid>
                          }
                        />
                      </GridRowColumn>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Card>
            </GridRowColumn>
            <GridRowColumn>
              <Accordion
                title={<Text as="h2">Completed</Text>}
                titleStyle={{ paddingLeft: 0, paddingRight: 0 }}
                content={
                  <Grid style={{ paddingTop: 7, paddingBottom: 7 }}>
                    {form1120s && form1120sCompleted && (
                      <GridRowColumn>
                        <FilingStatusByForm filingForm={form1120s} />
                      </GridRowColumn>
                    )}
                    {form1040 && form1040Completed && (
                      <GridRowColumn>
                        <FilingStatusByForm filingForm={form1040} />
                      </GridRowColumn>
                    )}
                    {showContractorFiling && (
                      <GridRowColumn>
                        <Accordion
                          title={'1099-NECs'}
                          content={<ContractorList />}
                          backgroundColor="stone40"
                          style={{
                            borderRadius: 8,
                            padding: 8,
                            marginTop: 0,
                          }}
                        />
                      </GridRowColumn>
                    )}
                    <ExtensionSurveyCard year={taxYear} />
                  </Grid>
                }
                backgroundColor="white"
              />
            </GridRowColumn>
          </Grid>
        </Grid.Column>
        <Grid.Column width={isMobileOrTablet ? 16 : 6}>
          <Grid>
            <GridRowColumn>
              <AnnualTaxImportantDateCard year={taxYear} />
            </GridRowColumn>
            <GridRowColumn>
              <AnnualTaxFaq />
            </GridRowColumn>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default UserMissedDeadlinePanel
