import axios from 'axios'
import { fetchWrapper } from '../../reducers/fetch'

export interface CalendlyEventDetails {
  name: string
  start_time: string
  end_time: string
  location: {
    join_url: string
  }
}

export const GET_CALENDLY_EVENT_DETAILS_KEY = 'GET_CALENDLY_EVENT_DETAILS_KEY'
export const getCalendlyEventDetails = (eventUri: string) =>
  fetchWrapper({
    fetchKey: GET_CALENDLY_EVENT_DETAILS_KEY,
    fetchFunction: async () => {
      const json = await axios.post<CalendlyEventDetails | null>(
        '/finances/api/v1/calendly/event_details',
        { eventUri }
      )
      return json.data
    },
  })
