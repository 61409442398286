import { ReactNode, useMemo } from 'react'
import { Accordion } from 'semantic-ui-react'
import styled from 'styled-components'
import { Button, Icon, Link } from '../BaseComponents'
import { Colors } from '../../styles/theme'
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom'
import { PagesProps } from '../../reducers/sidebar.slice'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

type Props = {
  children?: ReactNode
  className?: string
  disabled?: boolean
  href?: string
  icon?: IconDefinition
  match?: string
  newPage?: boolean
  ornament?: ReactNode
  sublabel?: ReactNode
} & (
  | {
      // If the nav section has a `to` prop, it represents a top level nav without any sublinks
      end?: boolean
      exact?: boolean
      match?: string
      onClick?: never
      open?: never
      pages?: never
      to?: string
      topRoute?: never
    }
  | {
      // If the nav section has a `pages` prop, it has sublinks beneath it and shouldn't link to
      // another page by itself
      end?: never
      exact?: never
      match?: never
      onClick?: () => void
      open?: boolean
      pages?: PagesProps[]
      to?: never
      topRoute?: string
    }
)

const getNavButtonClassName = ({
  className,
  exact,
  match,
  currentPath,
}: {
  className?: string
  exact?: boolean
  match?: string
  currentPath?: string
}) => {
  const isMatch = exact
    ? currentPath === match
    : match && currentPath?.startsWith(match)

  return `${className}${match ? (isMatch ? ' active' : '') : ''}`
}

const NavWrapper = ({
  children,
  className,
  href,
  newPage,
}: {
  children?: ReactNode
  className?: string
  href?: string
  newPage?: boolean
}) =>
  href ? (
    <Link className={className} href={href} newPage={newPage}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  )

export const NavButton = styled(
  ({
    children,
    className,
    disabled,
    end,
    exact,
    href,
    match,
    newPage,
    onClick,
    open,
    to,
    pages,
  }: Props) => {
    const { pathname } = useLocation()

    const icon = useMemo(() => {
      if (href) {
        return regular('external-link')
      } else if (pages?.length) {
        return open ? regular('chevron-up') : regular('chevron-down')
      }
      return null
    }, [href, open, pages?.length])

    return !disabled && to ? (
      <RouterNavLink
        className={getNavButtonClassName({
          className,
          exact,
          match,
          currentPath: pathname,
        })}
        end={end}
        to={to}
      >
        <Button className="nav-button" fullWidth>
          {children}
        </Button>
      </RouterNavLink>
    ) : (
      <NavWrapper
        className={className}
        href={disabled ? undefined : href}
        newPage={newPage}
      >
        <Button
          className="nav-button"
          disabled={disabled}
          fullWidth
          onClick={onClick}
        >
          {children}
          {icon && (
            <>
              <div className="spacer" />
              <Icon
                className="toggle-icon"
                color="moss"
                icon={icon}
                size="xs"
              />
            </>
          )}
        </Button>
      </NavWrapper>
    )
  }
)(({ disabled, to }) => ({
  '&&&&': {
    padding: 0,

    '&.active > .nav-button': {
      backgroundColor: Colors.green,
    },

    '.nav-button': {
      backgroundColor: Colors.transparent,
      border: 'none',
      borderRadius: '100px !important',
      color: disabled ? Colors.gray : Colors.sunrise,
      height: 'initial',
      justifyContent: 'left',
      lineHeight: '140%',
      marginTop: to ? 4 : 18,
      padding: '9px 12px',
      textAlign: 'left',

      '.sublabel': {
        color: Colors.lightGray,
        fontSize: '12px',
      },

      '.spacer': {
        flexGrow: 1,
      },

      'i.icon': {
        alignItems: 'center',
        display: 'flex',
      },

      '&:hover': {
        backgroundColor: Colors.green,
      },
    },
  },
}))

const NavLink = styled(
  ({
    className,
    children,
    disabled,
    end,
    exact,
    icon,
    match,
    open,
    onClick,
    ornament,
    pages,
    sublabel,
    to,
  }: Props) => (
    <div className={className}>
      <Accordion.Title>
        <NavButton
          disabled={disabled}
          {...(to ? { end, exact, match, to } : { onClick, open, pages })}
        >
          {icon && <Icon className="nav-icon" icon={icon} />}
          <span>
            {children}
            {sublabel}
          </span>
          <div className="spacer" />
          {ornament}
        </NavButton>
      </Accordion.Title>
      {pages && (
        <Accordion.Content active={open}>
          {pages.map(({ label, icon, ...rest }) => (
            <NavButton disabled={disabled} key={`NavButton_${label}`} {...rest}>
              {label}
              {icon && (
                <Icon
                  size="1x"
                  style={{ margin: '-1px 0 0 8px' }}
                  icon={icon}
                />
              )}
            </NavButton>
          ))}
        </Accordion.Content>
      )}
    </div>
  )
)(({ disabled }) => ({
  '&&&&': {
    'div.title': {
      cursor: disabled ? 'unset' : 'pointer',
      padding: 0,

      '.nav-icon': {
        marginRight: 8,
      },
    },
    'div.content': {
      padding: '0 !important',

      '.nav-button': {
        color: Colors.white,
        marginTop: 4,
        paddingLeft: 32,
      },
    },
  },
}))

export default NavLink

export type { PagesProps }
