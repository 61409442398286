import { useEffect, useState } from 'react'

import { Card, Label, Table, Text } from '../../../components/BaseComponents'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  adminFetchAdvisoryProfile,
  adminFetchUpriseMessages,
  adminFetchUserAdvisoryCalls,
  FinancialAdvisoryProfile,
} from '../../FinancialAdvisory/financialAdvisory.slice'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import Moment from 'react-moment'
import {
  Divider,
  TableBody,
  TableHeader,
  TableHeaderCell,
} from 'semantic-ui-react'
import { UserFinancialAdvisoryCallEvent } from '../../FinancialAdvisory/userFinancialAdvisoryCallEvents.slice'

const FinancialAdvisoryPanel = ({ userId }: { userId: number }) => {
  const dispatch = useAppDispatch()
  const [faProfile, setFaProfile] = useState<
    FinancialAdvisoryProfile | undefined
  >()
  const [callEvents, setCallEvents] = useState<
    UserFinancialAdvisoryCallEvent[] | undefined
  >()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [upriseMessages, setUpriseMessages] = useState<any[]>()
  useEffect(() => {
    const fetchData = async () => {
      const faProfile = await adminFetchAdvisoryProfile(userId)(dispatch)
      const events = await adminFetchUserAdvisoryCalls(userId)(dispatch)
      const messages = await adminFetchUpriseMessages(userId)(dispatch)
      setCallEvents(events)
      setFaProfile(faProfile)
      setUpriseMessages(messages)
    }
    fetchData()
  }, [dispatch, userId])

  return (
    <Card fullWidth>
      {faProfile && (
        <>
          <Text as="h2">
            Financial Advisory: <Label color="green">User Has Advisory</Label>
          </Text>
          <br />
          <Text as="h3">Advisory Status:</Text>
          <Text as="bodyMd"> {faProfile?.status}</Text>
          <br />
          <Text as="h3">Pilot Status:</Text>
          <Text as="bodyMd"> {faProfile?.pilotStatus}</Text>
          <br />

          <Text as="h3">Started Enrollment On:</Text>
          <Text as="bodyMd">
            {' '}
            <Moment format={DATE_FORMATS.DISPLAY_SHORT}>
              {faProfile?.createdAt}
            </Moment>
          </Text>
          <br />
          <Text as="h3">Completed Onboarding On:</Text>
          <Text as="bodyMd">
            {' '}
            {faProfile?.onboardingCompletedAt ? (
              <Moment format={DATE_FORMATS.DISPLAY_SHORT}>
                {faProfile?.onboardingCompletedAt}
              </Moment>
            ) : (
              'None'
            )}
          </Text>
          <br />
          <Text as="h3">Uprise Details:</Text>
          <ul>
            <li>
              <b>Uprise UUID:</b> {faProfile?.upriseUserUuid}
            </li>
            <li>
              <b>Uprise Financial Plan UUID:</b>{' '}
              {faProfile?.upriseSubscriptionUuid}
            </li>
            <li>
              <b>Uprise Subscription UUID:</b>{' '}
              {faProfile?.upriseFinancialPlanUuid}{' '}
            </li>
            <li>
              <b>Created At: </b>
              <Moment format={DATE_FORMATS.DISPLAY_SHORT}>
                {faProfile?.createdAt}
              </Moment>
            </li>
          </ul>
          <Divider />
          <Text as="h2">Advisory Calls</Text>
          <Table>
            <TableHeader>
              <TableHeaderCell>Id</TableHeaderCell>
              <TableHeaderCell>Event Date Time</TableHeaderCell>
              <TableHeaderCell>Event Name</TableHeaderCell>
              <TableHeaderCell>Scheduled At</TableHeaderCell>
            </TableHeader>
            <TableBody>
              {callEvents?.map((event) => (
                <Table.Row key={event.id}>
                  <Table.Cell>{event?.id}</Table.Cell>
                  <Table.Cell>
                    Start time:{' '}
                    <Moment format={DATE_FORMATS.DISPLAY_FULL}>
                      {event?.startTime}
                    </Moment>
                    <br />
                    End time:{' '}
                    <Moment format={DATE_FORMATS.DISPLAY_FULL}>
                      {event?.endTime}
                    </Moment>
                  </Table.Cell>
                  <Table.Cell>{event.rawCalendlyEventBlob?.name}</Table.Cell>
                  <Table.Cell>
                    <Moment format={DATE_FORMATS.DISPLAY_FULL}>
                      {event?.createdAt}
                    </Moment>
                  </Table.Cell>
                </Table.Row>
              ))}
            </TableBody>
          </Table>

          <Divider />
          <Text as="h2">Uprise Messages</Text>
          <Text as="bodyMd">Table of all messages between Uprise and User</Text>
          <Table>
            <TableHeader>
              <TableHeaderCell>Message Id</TableHeaderCell>
              <TableHeaderCell>Message Sent At</TableHeaderCell>
              <TableHeaderCell>Message Subject</TableHeaderCell>
              <TableHeaderCell>Message Content</TableHeaderCell>
              <TableHeaderCell>Sender</TableHeaderCell>
              <TableHeaderCell>Recipient</TableHeaderCell>
            </TableHeader>
            <TableBody>
              {upriseMessages &&
                Object.keys(upriseMessages).map((key: string) => {
                  const message =
                    upriseMessages[key as keyof typeof upriseMessages]?.message
                  return (
                    <Table.Row key={key}>
                      <Table.Cell>{key}</Table.Cell>
                      <Table.Cell>
                        <Moment format={DATE_FORMATS.DISPLAY_FULL}>
                          {message?.updatedAt}
                        </Moment>
                      </Table.Cell>
                      <Table.Cell>{message?.subject}</Table.Cell>
                      <Table.Cell>{message?.content}</Table.Cell>
                      <Table.Cell>
                        {message?.senderFirstName} {message?.senderLastName}
                      </Table.Cell>
                      <Table.Cell>
                        {message?.recipientFirstName}{' '}
                        {message?.recipientLastName}
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
            </TableBody>
          </Table>
        </>
      )}
      {!faProfile && (
        <>
          <Text as="h2">
            Financial Advisory: <Label color="red">No Advisory</Label>
          </Text>
          <Text as="h3">User has not enrolled Financial Adivsory</Text>
        </>
      )}
    </Card>
  )
}

export default FinancialAdvisoryPanel
