import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import AnnualTaxImportantDateCard from './AnnualTaxImportantDateCard'
import AnnualTaxFaq from './AnnualTaxFaq'
import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  Text,
  BorderedIcon,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import AnnualTaxFilingStatusCard from './AnnualTaxFilingStatusCard'
import {
  selectCurrentAnnualTaxYear,
  selectTaxDetailsByYear,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../utils/dateHelpers'
import { Colors } from '../../../../styles/theme'
import TaxCenterPromptCard, {
  PromptCardStatus,
  TaxPromptCardInfo,
} from '../components/TaxCenterPromptCard'
import {
  selectAllowEditTaxSeasonKickoffForYear,
  selectAnnualTaxFilingForYearUnconfirmed,
} from '../annualTaxFilings.selector'
import TaxSurveyOptOutModal from '../components/TaxSurveyOptOutModal'
import {
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../../../utils/deviceWidthHelpers'

const TaxSeasonKickoffMarkedUnsureCard = ({ year }: { year: string }) => (
  <TaxCenterPromptCard
    body={{
      [PromptCardStatus.COMING_SOON]: (
        <Grid>
          <GridRowColumn centerContent>
            <Image src="https://heard-images.s3.amazonaws.com/assets/laptop.svg" />
          </GridRowColumn>
          <GridRowColumn spacer={3} width={10}>
            <Text as="bodyLg">
              You can view your request in Conversations. After you’ve resolved
              your situation with Heard Support, you’ll receive an email
              notifying you that the Tax Season Kickoff is unpaused.
            </Text>
          </GridRowColumn>
        </Grid>
      ),
      [PromptCardStatus.ACTION_REQUIRED]: null,
      [PromptCardStatus.IN_PROGRESS]: null,
      [PromptCardStatus.DONE]: null,
    }}
    icon={regular('exclamation')}
    status={PromptCardStatus.COMING_SOON}
    title={`${year} Tax Season Kickoff`}
  />
)

const TaxSeasonKickoffOpenCard = ({ year }: { year: string }) => {
  const navigate = useNavigate()
  const [optOutModalOpen, setOptOutModalOpen] = useState(false)
  const annualTaxDetails = useReselector(selectTaxDetailsByYear, year)

  return (
    <Card type="subsection" backgroundColor="stone40">
      <Grid>
        <GridRowColumn
          columnStyle={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Icon
            icon={regular('exclamation')}
            style={{
              marginRight: 8,
              padding: '4px 11px',
              backgroundColor: Colors.black,
              borderRadius: 4,
            }}
            color="white"
          />
          <Text as="h2">{year} Tax Season Kickoff</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text color="orange">
            Due{' '}
            {annualTaxDetails?.taxSeasonKickoffDueAt &&
              isoToUTCDateTime(annualTaxDetails.taxSeasonKickoffDueAt).toFormat(
                DATE_FORMATS_LUXON.DISPLAY_LONG
              )}
          </Text>
        </GridRowColumn>
        <GridRowColumn centerContent>
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/tax-welcome-small.svg"
            style={{ height: 100, width: 148 }}
          />
        </GridRowColumn>
        <GridRowColumn centerContent spacer={3} width={10}>
          <Button
            onClick={() => navigate(`/taxes/annual/annual_tax_needs/${year}`)}
          >
            Continue
          </Button>
        </GridRowColumn>
        <GridRowColumn centerContent>
          <Button variant="actionLink" onClick={() => setOptOutModalOpen(true)}>
            I don’t need Heard to prepare my taxes
          </Button>
        </GridRowColumn>
        <TaxPromptCardInfo
          timeToComplete="3 minutes"
          docInfo="No documents required"
          dueDate={
            annualTaxDetails?.taxSeasonKickoffDueAt
              ? isoToUTCDateTime(annualTaxDetails.taxSeasonKickoffDueAt)
              : undefined
          }
        />
      </Grid>
      <TaxSurveyOptOutModal
        open={optOutModalOpen}
        close={() => setOptOutModalOpen(false)}
        year={year}
      />
    </Card>
  )
}

const AnnualTaxSignupClosed = ({ year }: { year: string }) => (
  <Card type="section">
    <Grid>
      <GridRowColumn>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BorderedIcon
            wrapperColor="forest"
            height={30}
            icon={regular('exclamation-circle')}
            color="white"
          />
          <Text as="h2" style={{ marginLeft: '10px' }}>
            The {year} tax service sign-up has closed
          </Text>
        </div>
      </GridRowColumn>
      <GridRowColumn>
        <Text>
          As a result, Heard will not provide {year} tax services for your
          account. However, you&apos;ll still need to complete your End-of-Year
          Review, as this step helps ensure an accurate tax return.
        </Text>
        <br />
        <Text>
          The Heard Team is still by your side this tax season, and we encourage
          you to reach out if you have any questions. We&apos;re here to support
          you.
        </Text>
      </GridRowColumn>
    </Grid>
  </Card>
)

const TaxSeasonKickoffIncomplete = () => {
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)
  const year = useReselector(selectCurrentAnnualTaxYear)
  const allowEditSurvey = useReselector(
    selectAllowEditTaxSeasonKickoffForYear,
    year
  )
  const filingUnconfirmed = useReselector(
    selectAnnualTaxFilingForYearUnconfirmed,
    year
  )

  const cardToDisplay = useMemo(() => {
    if (!allowEditSurvey) {
      return <AnnualTaxSignupClosed year={year} />
    } else if (filingUnconfirmed) {
      return <TaxSeasonKickoffMarkedUnsureCard year={year} />
    }

    return <TaxSeasonKickoffOpenCard year={year} />
  }, [allowEditSurvey, filingUnconfirmed, year])

  return (
    <Grid>
      {allowEditSurvey && <AnnualTaxFilingStatusCard year={year} />}
      <Grid.Row>
        <Grid.Column width={isMobileOrTablet ? 16 : 9}>
          {cardToDisplay}
        </Grid.Column>
        <Grid.Column width={isMobileOrTablet ? 16 : 6}>
          <Grid>
            <GridRowColumn>
              <AnnualTaxImportantDateCard year={year} />
            </GridRowColumn>
            <GridRowColumn>
              <AnnualTaxFaq />
            </GridRowColumn>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row />
    </Grid>
  )
}

export default TaxSeasonKickoffIncomplete
