import axios from 'axios'
import { Moment } from 'moment'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper, fetchWrapper } from '../../reducers/fetch'
import {
  FinancialAccountWithAdminInfo,
  receiveAdminDeleteUserFinancialAccount,
  receiveUserFinancialAccount,
  receiveUserFinancialAccounts,
} from '../../reducers/admin/financialAccountsReducer'
import { FinancialAccount } from '../../reducers/finances/financialAccountsReducer'
import { Transaction } from '../../reducers/admin/allTransactions.slice'
import {
  receiveAdminDeleteUserPlaidItem,
  receiveUserPlaidItem,
  receiveUpdateUserPlaidItemFinancialAccount,
} from '../../reducers/admin/plaidItemReducer'
import { AdminPlaidItem } from '../../reducers/finances/plaidItemReducer'

export const REQUEST_USER_FINANCIAL_ACCOUNTS_KEY = (userId: number | string) =>
  `REQUEST_USER_FINANCIAL_ACCOUNTS_KEY_${userId}`

export const fetchUserFinancialAccounts = (
  userId: number | string,
  alwaysFetch = false
) =>
  fetchIfNeededWrapper({
    alwaysFetch,
    fetchKey: REQUEST_USER_FINANCIAL_ACCOUNTS_KEY(userId),
    defaultErrorMessage: 'There was an error fetching financial accounts',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{
        accounts: FinancialAccountWithAdminInfo[]
        financialProfileId: number
      }>(`/finances/api/v1/admin/${userId}/accounts`)

      dispatch(
        receiveUserFinancialAccounts({
          accounts: keyBy(json.data.accounts, 'id'),
          userId: Number(userId),
        })
      )
      return true
    },
  })

export const updateAdminUserFinancialAccounts = (
  userId: number,
  accountId: number,
  data: Partial<FinancialAccountWithAdminInfo>
) =>
  fetchWrapper({
    fetchKey: REQUEST_USER_FINANCIAL_ACCOUNTS_KEY(userId),
    defaultErrorMessage: 'There was an error updating the financial account.',
    fetchFunction: async (dispatch) => {
      // Note that this does NOT come back with all admin financial account info
      const json = await axios.put<FinancialAccount>(
        `/finances/api/v1/admin/accounts/${accountId}`,
        data
      )
      dispatch(
        receiveUserFinancialAccount({
          account: {
            ...json.data,
            reconciliations: [],
            accounts: null,
            manualAccount: null,
          },
          userId,
        })
      )
      dispatch(
        receiveUpdateUserPlaidItemFinancialAccount({
          userId,
          account: json.data,
        })
      )
      return true
    },
  })

export const createUserFinancialAccount = (
  userId: number,
  data: {
    financialProfileId: number
    accountId: 'manual'
    accountType: 'manual'
    plaidInstitutionName: string
    name: string
    mask: string
    type: string
    subtype: string
    plaidAccountId?: number
  }
) =>
  fetchWrapper({
    fetchKey: REQUEST_USER_FINANCIAL_ACCOUNTS_KEY(userId),
    defaultErrorMessage: 'There was an error creating the financial account.',
    fetchFunction: (dispatch) =>
      axios
        .post<{
          account: FinancialAccountWithAdminInfo
          financialProfileId: number
        }>(`/finances/api/v1/admin/${userId}/accounts`, data)
        .then((json) => {
          dispatch(
            receiveUserFinancialAccount({
              account: {
                ...json.data.account,
              },
              userId,
            })
          )
          return json.data
        }),
  })

export const adminDeleteFinancialAccount = (
  userId: number,
  financialAccountId: number
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error removing the financial account.',
    fetchFunction: (dispatch) =>
      axios
        .delete<{
          deletedId: number
          financialProfileId: number
        }>(`/finances/api/v1/accounts/${financialAccountId}`)
        .then((json) => {
          dispatch(
            receiveAdminDeleteUserFinancialAccount({
              userId,
              deletedId: json.data.deletedId,
            })
          )
          return json.data
        }),
  })

export const fetchTransactionCountsSinceLastReconciliation = (
  userId: number | string
) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<{
          [key: number]: number
        }>(`/finances/api/v1/admin/${userId}/accounts/new_transaction_counts`)
        .then((json) => json.data),
  })

export interface FinancialAccountSumResponse extends FinancialAccount {
  transactionsSumInCents: string
  transactionSumIds: number[]
}

export const FETCH_FINANCIAL_ACCOUNT_TRANSACTION_SUM_KEY =
  'FETCH_FINANCIAL_ACCOUNT_TRANSACTION_SUM_KEY'
export const fetchFinancialAccountTransactionSum = (
  financialAccountId: number,
  startDate: Moment,
  endDate: Moment
) =>
  fetchWrapper({
    fetchKey: FETCH_FINANCIAL_ACCOUNT_TRANSACTION_SUM_KEY,
    fetchFunction: () =>
      axios
        .get<FinancialAccountSumResponse>(
          `/finances/api/v1/admin/accounts/account_sum/${financialAccountId}`,
          {
            params: {
              startDate: startDate.format('MM-DD-YYYY'),
              endDate: endDate.format('MM-DD-YYYY'),
              limit: 1000,
            },
          }
        )
        .then((json) => json.data),
  })

export const getDateOfLastTransaction = (financialAccountId: number) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<Transaction>(
          `/finances/api/v1/admin/accounts/${financialAccountId}/latest_transaction`
        )
        .then((json) => json.data),
  })

export const forcePullTransactions = (data: {
  plaidItemId?: number
  accountId?: number
}) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating the PlaidItem.',
    defaultValue: false,
    fetchFunction: () =>
      axios
        .post<{
          transactionIds: number[]
        }>('/finances/api/v1/admin/plaid/force_pull_transactions', data)
        .then((json) => json.data),
  })

export const forceNotifyUserReconnection = (data: {
  plaidItemId?: number
  accountId?: number
}) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error force notifying user.',
    defaultValue: false,
    fetchFunction: () =>
      axios
        .post<{
          status: string
        }>('/finances/api/v1/admin/plaid/force_notify_reconnect', data)
        .then((json) => json.data),
  })

export const adminLinkFinancialAccount = (
  userId: number,
  accountId: number,
  data: {
    userId: number
    financialAccountId: number
    plaidAccountId: number
  }
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error linking the manual account.',
    fetchFunction: async (dispatch) => {
      const json = await axios.put<{
        manualAccount: FinancialAccount
        plaidAccount: FinancialAccountWithAdminInfo
      }>(`/finances/api/v1/admin/accounts/link/${accountId}`, data)
      dispatch(
        receiveAdminDeleteUserFinancialAccount({
          userId,
          deletedId: json.data.manualAccount.id,
        })
      )
      dispatch(
        receiveUserFinancialAccount({
          account: {
            ...json.data.plaidAccount,
          },
          userId,
        })
      )
      return true
    },
  })

export const markPlaidItemInactive = (plaidItemId: number, userId: number) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error inactivating the Plaid Item.',
    fetchFunction: (dispatch) =>
      axios
        .post<AdminPlaidItem>(
          `/finances/api/v1/admin/plaid/items/${plaidItemId}`
        )
        .then((json) => {
          dispatch(
            receiveUserPlaidItem({
              userId,
              plaidItem: json.data,
            })
          )
          dispatch(fetchUserFinancialAccounts(userId, true))
          return json.data
        }),
  })

export const deletePlaidItem = (plaidItemId: number, userId: number) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error deleting the Plaid Item.',
    fetchFunction: (dispatch) =>
      axios
        .delete<number>(`/finances/api/v1/admin/plaid/items/${plaidItemId}`)
        .then((json) => {
          dispatch(
            receiveAdminDeleteUserPlaidItem({
              userId,
              deletedId: plaidItemId,
            })
          )
          dispatch(fetchUserFinancialAccounts(userId, true))
          return json.data
        }),
  })
