import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useReselector, useScrollRef } from '../../../../../utils/sharedHooks'
import {
  createIsSomeEnum,
  useAppDispatch,
} from '../../../../../utils/typeHelpers'
import { fetchUserTaxEstimates } from '../../userTaxEstimates.slice'
import { CALCULATE_SAFE_HARBOR_STEP } from './helpers'
import CalculateSafeHarborIntro from './CalculateLateHarborIntro'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import TotalTaxesAndIncome from './TotalTaxesAndIncome'
import FinishCalculateSafeHarbor from './FinishCalculateSafeHarbor'
import QuarterlyTaxRecommendations from './QuarterlyTaxRecommendations'
import PageHeader from '../../../../../components/shared/PageHeader'
import {
  selectActiveQuarterlyTaxEstimateDetails,
  selectIsSameOrAfterNewUserCutOffAt,
  selectQuarterDetailsForQuarterBeforeActiveQuarter,
} from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'

const isStep = createIsSomeEnum(CALCULATE_SAFE_HARBOR_STEP)

const CalculateLateHarborFlow = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { scrollRef, scrollToRef } = useScrollRef({ autoScroll: true })

  const stepParam = searchParams.get('step')
  const step = isStep(stepParam)
    ? stepParam
    : CALCULATE_SAFE_HARBOR_STEP.calculateSafeHarborIntro

  const afterNewUserCutOffAt = useReselector(selectIsSameOrAfterNewUserCutOffAt)
  /*
    if it's after the new user cutoff date, it means this is for a late joiner for the current quarter
    if it's before, it means the current quarter's checklist isn't open yet, so this is for a user that wasn't able to get their
    safe harbor for the previous quarter
  */

  const previousDetails = useReselector(
    selectQuarterDetailsForQuarterBeforeActiveQuarter
  )
  const currentDetails = useReselector(selectActiveQuarterlyTaxEstimateDetails)
  const details = afterNewUserCutOffAt ? currentDetails : previousDetails

  useEffect(() => {
    dispatch(fetchUserTaxEstimates())
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    scrollToRef()
  }, [scrollToRef])

  const content = useMemo(() => {
    switch (step) {
      case CALCULATE_SAFE_HARBOR_STEP.calculateSafeHarborIntro:
        return <CalculateSafeHarborIntro details={details} />
      case CALCULATE_SAFE_HARBOR_STEP.quarterlyTaxRecommendations:
        return <QuarterlyTaxRecommendations details={details} />
      case CALCULATE_SAFE_HARBOR_STEP.totalTaxesAndIncome:
        return <TotalTaxesAndIncome details={details} />
      case CALCULATE_SAFE_HARBOR_STEP.finish:
        return <FinishCalculateSafeHarbor details={details} />
      default:
        return step satisfies never
    }
  }, [step, details])

  if (!details) {
    return null
  }

  return (
    <>
      <span ref={scrollRef} />
      <PageHeader header="Calculate safe harbor" isMultiStep />
      {content}
    </>
  )
}

export default CalculateLateHarborFlow
