import { useEffect } from 'react'
import { Grid } from 'semantic-ui-react'

import AnnualTaxFaq from './AnnualTaxFaq'
import AnnualTaxFilingStatusCard from './AnnualTaxFilingStatusCard'
import AnnualTaxImportantDateCard from './AnnualTaxImportantDateCard'
import './AnnualTaxOverview.scss'
import {
  Accordion,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import {
  select1040FormForYear,
  select1120sFormForYear,
  selectAllFilingFormsComplete,
} from '../annualTaxFilingForms.selector'
import { useReselector } from '../../../../utils/sharedHooks'
import { ContractorList } from '../Ten99Filing/ContractorList'
import { fetchUserDocuments } from '../../../UserDocuments/userDocuments.slice'
import { fetchAboundTaxDocumentsForPayer } from '../aboundAnnualTaxFilings.slice'
import {
  getAnnualTaxFilingForYearSelector,
  selectShowNeedContractorFilingByYear,
} from '../annualTaxFilings.selector'
import {
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../../../utils/deviceWidthHelpers'
import MessageCardList from '../TaxFyle/MessageCardList'
import {
  FormFilingStatusDetailedStep,
  selectCurrentDetailedStepForForm,
} from './statusCard.selectors'
import { FilingStatusByForm } from './DetailedFormStatusItem'
import AnnualTaxWrapUpCard from '../components/AnnualTaxWrapUpCard'
import UsersnapTaxSeasonNPSSurvey from '../UsersnapTaxSeasonNPSSurvey'
import ExtensionSurveyCard from '../ExtensionSurvey/ExtensionSurveyCard'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'

const AnnualTaxProgressOverview = () => {
  const dispatch = useAppDispatch()
  const year = useReselector(selectCurrentAnnualTaxYear)
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)

  const taxFiling = useReselector(getAnnualTaxFilingForYearSelector, year)
  const showContractorFiling = useReselector(
    selectShowNeedContractorFilingByYear,
    year
  )
  const allFormsComplete = useReselector(
    selectAllFilingFormsComplete,
    taxFiling?.id
  )
  const form1120s = useReselector(select1120sFormForYear, year)
  const form1040 = useReselector(select1040FormForYear, year)
  const form1120sDetailedStep = useReselector(
    selectCurrentDetailedStepForForm,
    year,
    form1120s?.id ?? ''
  )
  const form1120sCompleted =
    form1120sDetailedStep === FormFilingStatusDetailedStep.completed
  const form1040DetailedStatus = useReselector(
    selectCurrentDetailedStepForForm,
    year,
    form1040?.id ?? ''
  )
  const form1040Completed =
    form1040DetailedStatus === FormFilingStatusDetailedStep.completed

  useEffect(() => {
    dispatch(fetchUserDocuments())
    dispatch(fetchAboundTaxDocumentsForPayer())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])

  return (
    <Grid>
      <AnnualTaxFilingStatusCard year={year} />
      <UsersnapTaxSeasonNPSSurvey />
      <Grid.Row>
        <Grid.Column width={isMobileOrTablet ? 16 : 10}>
          <Grid>
            <GridRowColumn>
              <Text as="h2">Communication</Text>
            </GridRowColumn>
            <MessageCardList inTaxCenter />
            <GridRowColumn>
              <Text as="h2">Now</Text>
            </GridRowColumn>
            {allFormsComplete && <AnnualTaxWrapUpCard year={year} />}
            {form1120s && !form1120sCompleted && (
              <GridRowColumn>
                <FilingStatusByForm filingForm={form1120s} />
              </GridRowColumn>
            )}
            {form1040 && !form1040Completed && (
              <GridRowColumn>
                <FilingStatusByForm filingForm={form1040} />
              </GridRowColumn>
            )}
            <GridRowColumn>
              <Accordion
                title={<Text as="h2">Completed</Text>}
                titleStyle={{ paddingLeft: 0, paddingRight: 0 }}
                content={
                  <Grid style={{ paddingTop: 7, paddingBottom: 7 }}>
                    {form1120s && form1120sCompleted && (
                      <GridRowColumn>
                        <FilingStatusByForm filingForm={form1120s} />
                      </GridRowColumn>
                    )}
                    {form1040 && form1040Completed && (
                      <GridRowColumn>
                        <FilingStatusByForm filingForm={form1040} />
                      </GridRowColumn>
                    )}
                    {showContractorFiling && (
                      <GridRowColumn>
                        <Accordion
                          title={'1099-NECs'}
                          content={<ContractorList />}
                          backgroundColor="stone40"
                          style={{
                            borderRadius: 8,
                            padding: 8,
                            marginTop: 0,
                          }}
                        />
                      </GridRowColumn>
                    )}
                    <ExtensionSurveyCard year={year} />
                  </Grid>
                }
                backgroundColor="white"
              />
            </GridRowColumn>
          </Grid>
        </Grid.Column>
        <Grid.Column width={isMobileOrTablet ? 16 : 6}>
          <Grid>
            <GridRowColumn>
              <AnnualTaxImportantDateCard year={year} />
            </GridRowColumn>
            <GridRowColumn>
              <AnnualTaxFaq />
            </GridRowColumn>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default AnnualTaxProgressOverview
