import { useEffect } from 'react'
import { Grid, Container, Divider } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import {
  Button,
  Card,
  FormikCheckbox,
  FormikLabelError,
  FormikRadioToggleButton,
  getFieldName,
  GridRowColumn,
  Link,
  makeReqBoolSchema,
  Text,
} from '../../../components/BaseComponents'
import { canOptIntoPreviousYearTaxes, SIGNUP_PATHS } from '../helpers'
import SignupHeader from '../SignupHeader'
import { useSignupPageUpdate } from '../../../services/onboardingService'
import { useAnalyticsView } from '../../Amplitude'
import {
  useNavigateWithPersistParams,
  useReselector,
} from '../../../utils/sharedHooks'
import { useSaveAnnualTaxFilingFormData } from '../../Taxes/AnnualTaxes/helpers'
import { fetchAnnualTaxFilingsIfNeeded } from '../../Taxes/AnnualTaxes/annualTaxFilings.slice'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import {
  getAnnualTaxFilingForYearSelector,
  selectAnnualTaxNotAvailable,
} from '../../Taxes/AnnualTaxes/annualTaxFilings.selector'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { getFinancialProfile } from '../../../selectors/user.selectors'
import TaxTerms from './TaxTerms'
import {
  selectCurrentAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
} from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TAX_ENTITY_TYPES } from '../../Taxes/taxConstants'
import { DateTime } from 'luxon'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { convertUtcToLocalDate } from '../../../utils/dateHelpers'

const ActivePreviousYearTaxForm = () => {
  const fp = useReselector(getFinancialProfile)
  const navigate = useNavigateWithPersistParams()
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)

  const taxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentTaxYear
  )

  const saveTaxFiling = useSaveAnnualTaxFilingFormData(currentTaxYear)
  const formik = useFormik({
    initialValues: {
      needPreviousTaxYearReturn: taxFiling
        ? taxFiling?.optedOutAt === null
        : null,
      confirmedTerms: undefined,
      extensionRequested: taxFiling?.extensionRequestedAt ? true : null,
    },
    enableReinitialize: true,
    onSubmit: async ({
      needPreviousTaxYearReturn,
      confirmedTerms,
      extensionRequested,
    }) => {
      // If user opts into tax filing, we confirm and create an AnnualTaxFiling with the late joiner flag
      // Users will still need to confirm via TSK, but these fields enable us to confirm a User's tax filing needs
      if (needPreviousTaxYearReturn) {
        const res = await saveTaxFiling({
          year: currentTaxYear,
          extensionRequestedAt: extensionRequested
            ? new Date().toISOString()
            : null,
          isLateJoiner: true,
          isLateJoinerTermsAcceptedAt: confirmedTerms
            ? new Date().toISOString()
            : null,
          optedOutAt: null,
        })

        if (res) {
          navigate(SIGNUP_PATHS.choosePlan)
        }
      } else {
        const res = await saveTaxFiling({
          year: currentTaxYear,
          optedOutAt: new Date().toISOString(),
          // used to track when users accepted opt out terms
          isLateJoiner: false,
          isLateJoinerTermsAcceptedAt: confirmedTerms
            ? new Date().toISOString()
            : null,
        })
        if (res) {
          navigate(SIGNUP_PATHS.choosePlan)
        }
      }
    },
  })
  const activeDetails = useReselector(selectCurrentAnnualTaxDetails)

  if (!activeDetails?.taxQuestionnaireDueDates) {
    return null
  }

  const form1040LateJoinerOnTimeCutoffDate = convertUtcToLocalDate(
    activeDetails.taxQuestionnaireDueDates.form_1040.lateJoinerOnTimeCutoffDate
  ).endOf('day')
  // Used to toggle highlight of sign up header dependent on whether we show late sign up screen
  const currentStep = canOptIntoPreviousYearTaxes() ? 3 : 2
  const allowExtensionOptIn =
    fp?.taxEntityType === TAX_ENTITY_TYPES.form_1040 &&
    DateTime.now() <= form1040LateJoinerOnTimeCutoffDate

  const { values, submitForm, isSubmitting, isValid } = formik

  return (
    <Container style={{ paddingTop: 64 }}>
      <FormikProvider value={formik}>
        <Grid>
          <SignupHeader currentStep={currentStep} />
          <GridRowColumn>
            <Text as="h1">
              Inform us of your {currentTaxYear} Tax Filing needs
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodyLg">
              Let us know if you are looking for {currentTaxYear} annual tax
              support. Since you joined in {Number(currentTaxYear) + 1}, terms
              will apply to ensure a smooth tax filing experience.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <TaxTerms
              taxEntityType={fp?.taxEntityType}
              currentTaxYear={currentTaxYear}
            />
          </GridRowColumn>
          <GridRowColumn>
            <FormikLabelError
              name={getFieldName<typeof values>('needPreviousTaxYearReturn')}
              schema={yup.boolean().nullable().required()}
            />
          </GridRowColumn>
          <GridRowColumn>
            <Text as="h3">
              Would you like Heard to prepare your {currentTaxYear} taxes?
            </Text>
          </GridRowColumn>
          <Grid.Row className="short">
            <Grid.Column width={3}>
              <FormikRadioToggleButton
                name={getFieldName<typeof values>('needPreviousTaxYearReturn')}
                value
                fullWidth
              >
                Yes
              </FormikRadioToggleButton>
            </Grid.Column>
            <Grid.Column width={3}>
              <FormikRadioToggleButton
                name={getFieldName<typeof values>('needPreviousTaxYearReturn')}
                value={false}
                fullWidth
              >
                No
              </FormikRadioToggleButton>
            </Grid.Column>
          </Grid.Row>
          {values.needPreviousTaxYearReturn && allowExtensionOptIn && (
            <>
              <GridRowColumn>
                <Text as="h3">
                  Would you like Heard to file an extension for you?
                </Text>
                <Text as="bodySm">
                  Filing an extension gives you more time to gather your
                  required documents and information and helps you avoid the
                  “failure to file” penalty. By filing an extension, the
                  deadlines on items 1-4 of the {currentTaxYear} Tax Preparation
                  Terms wouldn’t apply to you. However, you’ll still need to pay
                  an estimated amount of owed taxes before the IRS filing
                  deadline.{' '}
                  <Link
                    href="https://support.joinheard.com/hc/en-us/articles/12962191242007-Filing-an-extension-for-your-personal-income-tax-return"
                    newPage
                  >
                    Learn more.
                  </Link>
                </Text>
              </GridRowColumn>
              <Grid.Row className="short">
                <Grid.Column width={3}>
                  <FormikRadioToggleButton
                    name={getFieldName<typeof values>('extensionRequested')}
                    value
                    fullWidth
                  >
                    Yes
                  </FormikRadioToggleButton>
                </Grid.Column>
                <Grid.Column width={3}>
                  <FormikRadioToggleButton
                    name={getFieldName<typeof values>('extensionRequested')}
                    value={false}
                    fullWidth
                  >
                    No
                  </FormikRadioToggleButton>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          {values.needPreviousTaxYearReturn && (
            <GridRowColumn>
              <Card type="section" variant="emphasis">
                By opting in to {currentTaxYear} taxes, you agree to all of the
                above terms. You understand that meeting these deadlines is
                necessary to avoid additional penalties and interest on your tax
                payment.
                <br />
                <br />
                <FormikCheckbox
                  label="I have read and agreed to all the terms."
                  name={getFieldName<typeof values>('confirmedTerms')}
                  schema={makeReqBoolSchema({
                    error: 'The terms and conditions must be accepted.',
                  })}
                />
              </Card>
            </GridRowColumn>
          )}

          {values.needPreviousTaxYearReturn === false && (
            <GridRowColumn>
              <Card type="section" variant="emphasis">
                By opting out, you understand that Heard will not do your
                personal or business taxes for {currentTaxYear}. If you want to
                opt back in later, you will need to contact the Heard Team
                through Conversations.
                <br />
                <br />
                <FormikCheckbox
                  name={getFieldName<typeof values>('confirmedTerms')}
                  label="I have read and agreed to all the terms."
                  schema={makeReqBoolSchema({
                    error: 'The terms and conditions must be accepted.',
                  })}
                />
              </Card>
            </GridRowColumn>
          )}

          <Grid.Row />
          <Divider />
          {!isMobile && (
            <Grid.Row>
              <Grid.Column width={2}>
                <Button
                  variant="secondary"
                  fullWidth
                  onClick={() => navigate(SIGNUP_PATHS.practiceJourney)}
                >
                  Back
                </Button>
              </Grid.Column>
              <Grid.Column width={12} />
              <Grid.Column width={2}>
                <Button
                  fullWidth
                  onClick={submitForm}
                  disabled={!isValid || isSubmitting}
                  id="btn-signup-previous-year-form"
                  loading={isSubmitting}
                >
                  Continue
                </Button>
              </Grid.Column>
            </Grid.Row>
          )}
          {isMobile && (
            <>
              <GridRowColumn>
                <Button
                  fullWidth
                  onClick={submitForm}
                  disabled={!isValid || isSubmitting}
                  id="btn-signup-previous-year-form"
                  loading={isSubmitting}
                >
                  Continue
                </Button>
              </GridRowColumn>
              <GridRowColumn>
                <Button
                  variant="secondary"
                  fullWidth
                  onClick={() => navigate(SIGNUP_PATHS.aboutYourPractice)}
                >
                  Back
                </Button>
              </GridRowColumn>
            </>
          )}
          <Grid.Row />
          <Grid.Row />
        </Grid>
      </FormikProvider>
    </Container>
  )
}

const DisqualifiedPage = () => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const navigate = useNavigateWithPersistParams()
  const currentStep = canOptIntoPreviousYearTaxes() ? 3 : 2
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)

  return (
    <Container style={{ paddingTop: 64 }}>
      <Grid>
        <SignupHeader currentStep={currentStep} />
        <GridRowColumn>
          <Text as="bodyLg">
            <b>
              We&apos;re working hard to prepare for this year&apos;s tax
              season.
            </b>
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            Helping you file your taxes for this year (
            {Number(currentTaxYear) + 1}) is part of your plan.
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            However, we are unable to help you file your taxes for last year (
            {currentTaxYear}). If you still need help with your {currentTaxYear}{' '}
            taxes, we recommend seeking assistance outside of Heard.
          </Text>
        </GridRowColumn>

        <Divider />
        {!isMobile && (
          <Grid.Row>
            <Grid.Column width={2}>
              <Button
                variant="secondary"
                fullWidth
                onClick={() => navigate(SIGNUP_PATHS.practiceJourney)}
              >
                Back
              </Button>
            </Grid.Column>
            <Grid.Column width={12} />
            <Grid.Column width={2}>
              <Button
                fullWidth
                onClick={() => navigate(SIGNUP_PATHS.choosePlan)}
                id="btn-signup-previous-year-form"
              >
                Continue
              </Button>
            </Grid.Column>
          </Grid.Row>
        )}
        {isMobile && (
          <>
            <GridRowColumn>
              <Button
                fullWidth
                onClick={() => navigate(SIGNUP_PATHS.choosePlan)}
                id="btn-signup-previous-year-form"
              >
                Continue
              </Button>
            </GridRowColumn>
            <GridRowColumn>
              <Button
                variant="secondary"
                fullWidth
                onClick={() => navigate(SIGNUP_PATHS.aboutYourPractice)}
              >
                Back
              </Button>
            </GridRowColumn>
          </>
        )}
      </Grid>
    </Container>
  )
}
const PreviousYearTaxForm = () => {
  const dispatch = useAppDispatch()
  const pageView = useAnalyticsView()

  const isNotAvailable = useReselector(selectAnnualTaxNotAvailable)

  useEffect(() => {
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchAnnualTaxFilingsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    pageView('previous year tax form')
  }, [pageView])

  useSignupPageUpdate(SIGNUP_PATHS.previousYearTax)

  if (isNotAvailable) {
    return <DisqualifiedPage />
  } else {
    return <ActivePreviousYearTaxForm />
  }
}

export default PreviousYearTaxForm
