import { useMemo, useState } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Grid } from 'semantic-ui-react'
import {
  Card,
  Text,
  Button,
  Icon,
  BorderedIcon,
} from '../../components/BaseComponents'
import CalendlyPopupModal from '../../components/shared/CalendlyPopupModal'
import { useReselector } from '../../utils/sharedHooks'
import { useAppDispatch } from '../../utils/typeHelpers'
import { getCurrentUser } from '../../selectors/user.selectors'
import { isoToUTCDateTime } from '../../utils/dateHelpers'
import { useAnalyticsTrack } from '../Amplitude'
import { markUserActionItemCompleteIfExists } from '../Dashboard/UserActionItems/service'
import { ScheduleOnboardingCallStatus } from '../Dashboard/UserActionItems/userActionItemStatuses'
import {
  getCalendlyEventDetails,
  CalendlyEventDetails,
} from './calendlyActions'
import { OnboardingTaskIdents } from './config'

export const GroupOnboardingCard = ({
  sendOnboardingCompleteSideEffects,
}: {
  sendOnboardingCompleteSideEffects?: () => Promise<void>
}) => {
  const [calendlyOpen, setCalendlyOpen] = useState(false)
  const [registered, setRegistered] = useState(false)
  const [eventDetails, setEventDetails] = useState<
    CalendlyEventDetails | undefined | null
  >()

  const dispatch = useAppDispatch()

  const track = useAnalyticsTrack()

  const user = useReselector(getCurrentUser)

  const eventTimeString = useMemo(() => {
    if (user?.onboardingMeetingAt) {
      const startDate = isoToUTCDateTime(user.onboardingMeetingAt).toLocal()
      return startDate.toFormat('h:mma, EEEE, LLLL dd, yyyy')
    }
    if (!eventDetails) return ''
    const { start_time, end_time } = eventDetails
    const startDate = isoToUTCDateTime(start_time).toLocal()
    const endDate = isoToUTCDateTime(end_time).toLocal()
    const startTime = startDate.toFormat('h:mma')
    const endTime = endDate.toFormat('h:mma')
    const dayOfWeek = startDate.toFormat('EEEE, LLLL dd, yyyy')

    return `${startTime} - ${endTime}, ${dayOfWeek}`
  }, [eventDetails, user])

  const groupOnboardingUrl = process.env.VITE_ONBOARDING_MEETING_LINK

  return (
    <Card backgroundColor="stone40">
      <Grid>
        <Grid.Row verticalAlign="middle">
          {!registered && (
            <Grid.Column width={11}>
              <Text as="h3" style={{ marginBottom: 8 }}>
                Register for a group onboarding call
              </Text>
              <Text style={{ marginBottom: 8 }}>
                During this session, our Onboarding Team will walk you through
                key features in Heard. If you have tax-related questions, visit
                our Resource Hub.
              </Text>
              <Button variant="link" onClick={() => setCalendlyOpen(true)}>
                Register
                <Icon
                  icon={regular('arrow-right')}
                  color="green"
                  style={{ marginLeft: 6 }}
                />
              </Button>
            </Grid.Column>
          )}
          {registered && (
            <>
              <Grid.Column width={1}>
                <BorderedIcon
                  icon={regular('check')}
                  height={30}
                  color="white"
                  wrapperColor="green"
                  size="sm"
                />
              </Grid.Column>
              <Grid.Column width={10} style={{ marginLeft: 8 }}>
                <Text as="h3" style={{ marginBottom: 8 }}>
                  You&apos;ve registered for a group onboarding call
                </Text>

                {eventTimeString && (
                  <Text style={{ marginBottom: 8 }}>{eventTimeString}</Text>
                )}
                <Text> Heard Onboarding Team </Text>
              </Grid.Column>
            </>
          )}
          <Grid.Column width={4}>
            <img
              src="https://heard-images.s3.amazonaws.com/assets/GOW_images.svg"
              alt="group_onboarding_webinar_pictures"
              width={164}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {groupOnboardingUrl && (
        <CalendlyPopupModal
          url={groupOnboardingUrl}
          open={calendlyOpen}
          onClose={() => setCalendlyOpen(false)}
          onScheduled={async (eventUri: string | undefined) => {
            await sendOnboardingCompleteSideEffects?.()
            await markUserActionItemCompleteIfExists(
              OnboardingTaskIdents.SCHEDULE_ONBOARDING_MEETING,
              track,
              ScheduleOnboardingCallStatus.scheduled
            )
            track('completed onboarding item schedule call', {
              status: 'done',
            })
            if (eventUri) {
              const calendlyResponse = await dispatch(
                getCalendlyEventDetails(eventUri)
              )
              setEventDetails(calendlyResponse)
            }
            setRegistered(true)
          }}
        />
      )}
    </Card>
  )
}
