import { ElementType, ReactNode, useState } from 'react'
import { BrowserRouter, UNSAFE_LocationContext } from 'react-router-dom'
import { Grid, SemanticWIDTHS } from 'semantic-ui-react'

import { Button, Card, GridRowColumn, Link, Text } from '../BaseComponents'
import {
  ADMIN_ROUTES,
  EXTERNAL_ADMIN_ROUTES,
  PAYROLL_ROUTES,
  PRIVATE_ROUTES,
  PUBLIC_ROUTES,
  RouteConfig,
} from './config'
import { useReselector } from '../../utils/sharedHooks'
import { getCurrentUser, getUserIsAdmin } from '../../selectors/user.selectors'

const RouteScreen = ({
  screenWidth,
  columnWidth,
  children,
}: {
  screenWidth: number
  columnWidth: SemanticWIDTHS
  children: ReactNode
}) => (
  <Grid.Column width={columnWidth}>
    <div
      style={{
        transform: 'scale(0.4)',
        transformOrigin: '0% 0% 0px',
        width: screenWidth,
      }}
    >
      {children}
    </div>
  </Grid.Column>
)

const DirectoryRow = ({
  link,
  Component,
}: {
  link: string
  Component: ElementType
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <GridRowColumn>
        <Link to={link}>{link}</Link>
        <Button onClick={() => setIsOpen(true)}>Load</Button>
      </GridRowColumn>
      {isOpen && (
        // @ts-expect-error hack to prevent navigation in sub components
        <UNSAFE_LocationContext.Provider value={null}>
          <BrowserRouter>
            <Grid.Row
              className="short"
              style={{ maxHeight: 250, overflowY: 'scroll', paddingTop: 10 }}
            >
              <RouteScreen columnWidth={7} screenWidth={1300}>
                <Component />
              </RouteScreen>
              <RouteScreen columnWidth={5} screenWidth={768}>
                <Component />
              </RouteScreen>
              <RouteScreen columnWidth={4} screenWidth={375}>
                <Component />
              </RouteScreen>
            </Grid.Row>
          </BrowserRouter>
        </UNSAFE_LocationContext.Provider>
      )}
    </>
  )
}

const RouteSection = ({
  title,
  config,
}: {
  title: string
  config: RouteConfig
}) => (
  <Card type="subsection" backgroundColor="lightGreen">
    <Grid>
      <GridRowColumn>
        <Text as="h2">{title}</Text>
      </GridRowColumn>
      {Object.entries(config).map(([key, route]) => (
        <DirectoryRow key={key} link={key} Component={route.component} />
      ))}
    </Grid>
  </Card>
)

const DirectoryPanel = () => {
  const currentUser = useReselector(getCurrentUser)
  const isAdmin = useReselector(getUserIsAdmin)

  return (
    <Grid style={{ margin: 30 }}>
      <GridRowColumn>
        <Text as="h1">Directory</Text>
      </GridRowColumn>

      <GridRowColumn>
        <Text>Logged In: {Boolean(currentUser).toString()}</Text>
        <Text>Is Admin: {Boolean(isAdmin).toString()}</Text>
      </GridRowColumn>

      <RouteSection title="External Admin" config={EXTERNAL_ADMIN_ROUTES} />
      <RouteSection title="Admin" config={ADMIN_ROUTES} />
      <RouteSection title="Logged in" config={PRIVATE_ROUTES} />
      <RouteSection title="Payroll" config={PAYROLL_ROUTES} />
      <RouteSection title="Logged out" config={PUBLIC_ROUTES} />
    </Grid>
  )
}

export default DirectoryPanel
