import axios from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper, fetchWrapper } from '../../../reducers/fetch'

interface IrsFormDueDate {
  irs: {
    dueDate: string
    extendedDueDate: string
    extensionFileDueDate: string
  }
  internal: {
    dueDate: string
    extendedDueDate: string
    extensionRequestCutoffDate: string
  }
}

interface TaxQuestionnaireDueDates {
  endAt: string //The initial deadline for completing the tax checklist for an on-time filing
  startAt: string //The date where the tax checklist becomes available
  cutoffDate: string //The true hard cutoff date for completing the tax checklist
  lateJoinerOnTimeCutoffDate: string //If a late joiner starts before this date, they won't be required to file an extension
  lateJoinerAutomaticExtensionCutoffDate: string //If a late joiner joins before this date but after lateJoinerOnTimeCutoffDate, they will be required to file an extension
  lateJoinerFinalCutoffDate: string //If a user joins after this date, they will not be allowed to file their annual taxes for the tax year
}

// Client-side models
export interface AnnualTaxDetail {
  taxYear: string
  newUserCutOffAt: string
  irsFormDueDates: { form_1040: IrsFormDueDate; form_1120_s: IrsFormDueDate }
  taxQuestionnaireDueDates: {
    form_1040: TaxQuestionnaireDueDates
    form_1120_s: TaxQuestionnaireDueDates
  } | null
  status: AnnualTaxDetailStatuses
  financialOverviewDueAt: string
  taxSeasonKickoffStartAt: string
  taxSeasonKickoffDueAt: string
  ten99NECFormsStartAt: string
  ten99NECFormsDueAt: string
  createdAt: string
  updatedAt: string
  modifiedBy?: number | null
}

export enum AnnualTaxDetailStatuses {
  current = 'current',
  future = 'future',
  past = 'past',
}

export interface AnnualTaxDetailsState {
  [key: string]: AnnualTaxDetail
}

// Our slice object contains the reducer, which modifies state for this entity
const annualTaxDetailsSlice = createSlice({
  name: 'annualTaxDetails',
  initialState: {} as AnnualTaxDetailsState,
  reducers: {
    receiveSingleAnnualTaxDetail: (
      state,
      action: PayloadAction<AnnualTaxDetail>
    ) => {
      state[action.payload.taxYear] = action.payload
    },
    receiveAllAnnualTaxDetails: (
      state,
      action: PayloadAction<{
        [key: string]: AnnualTaxDetail
      }>
    ) => ({ ...state, ...action.payload }),
  },
})

// Exported so we can access within appReducer.ts
export default annualTaxDetailsSlice.reducer

// These functions are used to manipulate our Redux state
const { receiveSingleAnnualTaxDetail, receiveAllAnnualTaxDetails } =
  annualTaxDetailsSlice.actions

/*
  Remaining logic below provides REST functionality to our UI components
*/

// Keys used within our fetchWrapper. Sometimes used by UI components for ascertaining the request's state
export const FETCH_ALL_ANNUAL_TAX_DETAILS_KEY =
  'FETCH_ALL_ANNUAL_TAX_DETAILS_KEY'
export const UPDATE_SINGLE_ANNUAL_TAX_DETAIL_KEY =
  'UPDATE_SINGLE_ANNUAL_TAX_DETAIL_KEY'
export const CREATE_SINGLE_ANNUAL_TAX_DETAIL_KEY =
  'CREATE_SINGLE_ANNUAL_TAX_DETAIL_KEY'

// These functions are called by our UI components

// For entities whose data changes infrequently, we use the "IfNeeded()" pattern to prevent unnecessary requests
export const fetchAllAnnualTaxDetailsIfNeeded = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
    defaultErrorMessage: 'Error fetching all annual tax details',
    fetchFunction: async (dispatch) => {
      // Make the GET request
      const json = await axios.get<AnnualTaxDetail[]>(
        '/finances/api/v1/annual_tax_details'
      )

      // Update Redux state with data
      dispatch(receiveAllAnnualTaxDetails(keyBy(json.data, 'taxYear')))
      return json.data
    },
  })

export const updateSingleAnnualTaxDetail = (
  taxYear: string,
  data: Partial<AnnualTaxDetail>
) =>
  fetchWrapper({
    fetchKey: UPDATE_SINGLE_ANNUAL_TAX_DETAIL_KEY + taxYear,
    defaultErrorMessage: 'Error updating the annual tax detail',
    fetchFunction: async (dispatch) => {
      const url = `/finances/api/v1/admin/annual_tax_details/${taxYear}`
      const json = await axios.put<AnnualTaxDetail>(url, data)
      dispatch(receiveSingleAnnualTaxDetail(json.data))
      return json.data
    },
  })

export const createSingleAnnualTaxDetail = (data: Partial<AnnualTaxDetail>) =>
  fetchWrapper({
    fetchKey: CREATE_SINGLE_ANNUAL_TAX_DETAIL_KEY,
    defaultErrorMessage: 'Error creating the annual tax detail',
    fetchFunction: async (dispatch) => {
      const url = '/finances/api/v1/admin/annual_tax_details'
      const json = await axios.post<AnnualTaxDetail>(url, data)
      dispatch(receiveSingleAnnualTaxDetail(json.data))
      return json.data
    },
  })
