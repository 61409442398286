import { useCallback, useEffect, useMemo, useState } from 'react'
import { Card as SemanticCard, Divider, Grid, Label } from 'semantic-ui-react'
import moment from 'moment'
import { AdminPlaidItem } from '../../../reducers/finances/plaidItemReducer'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import {
  FinancialAccount,
  TransactionSyncState,
} from '../../../reducers/finances/financialAccountsReducer'
import {
  Accordion,
  Button,
  Card,
  GridRowColumn,
  Text,
} from '../../BaseComponents'
import { useReselector, useToggle } from '../../../utils/sharedHooks'
import {
  AdminBankAccessStatusModal,
  AdminDeleteModal,
  AdminFinancialErrorModal,
  AdminRemoveModal,
} from './AdminConfirmModals'
import {
  getLinkedManualAccountsByUserId,
  selectAdminHiddenAccounts,
  selectAdminVisibleAccounts,
} from '../../../selectors/financeSelectors'
import {
  forceNotifyUserReconnection,
  forcePullTransactions,
  getDateOfLastTransaction,
} from '../../../actions/admin/adminFinancialAccountActions'
import { useAnalyticsTrack } from '../../../features/Amplitude'
import { Colors } from '../../../styles/theme'
import { fetchRolledOutInstitutionIds } from '../../../actions/userActions'
import { selectRolledOutInstitutionIds } from '../../../selectors/user.selectors'
import { checkIsInRolledOutInstitutions } from './helpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

interface AdminAccountCardProps {
  account: FinancialAccount
  noRecentPlaidTransactions: boolean
  needsReconnection: boolean
  userId: number
  isInactive: boolean
  dateOfLastTransaction: string | null
}

const AdminAccountCard = ({
  account,
  noRecentPlaidTransactions,
  needsReconnection,
  userId,
  isInactive,
  dateOfLastTransaction,
}: AdminAccountCardProps) => {
  const linkedManualAccounts = useReselector(
    getLinkedManualAccountsByUserId,
    userId
  )
  const rolledOutInstitutionIds = useReselector(selectRolledOutInstitutionIds)
  const isInRolledOutInstitutions = checkIsInRolledOutInstitutions(
    rolledOutInstitutionIds,
    account.plaidInstitutionId ?? ''
  )

  const linkedManualAccount = useMemo(() => {
    if (!linkedManualAccounts) return null
    return linkedManualAccounts.find(
      (manualAccount) =>
        manualAccount?.manualAccount?.financialAccountId === account.id
    )
  }, [linkedManualAccounts, account])

  return (
    <Card
      padding={16}
      backgroundColor={
        !isInactive && (noRecentPlaidTransactions || needsReconnection)
          ? 'accentRed'
          : 'stone'
      }
      variant={isInactive ? 'disabled' : undefined}
    >
      <Grid>
        <GridRowColumn>
          {account.statementPermissions === 'plaid_statement' ? (
            <Label color="blue">Plaid Statement</Label>
          ) : account.bankAccessEnabledAt ? (
            <Label color="violet">LBA</Label>
          ) : null}
        </GridRowColumn>
        <GridRowColumn short>
          <Text
            as="bodySm"
            style={{
              ...(isInRolledOutInstitutions && account.isHidden
                ? { color: Colors.mediumGray }
                : {}),
            }}
          >
            <b>
              {account.name}: {account.mask}
            </b>
          </Text>
          <Text
            as="bodyXs"
            style={{
              ...(isInRolledOutInstitutions && account.isHidden
                ? { color: Colors.mediumGray }
                : {}),
            }}
          >
            {account.type} - {account.subtype}
          </Text>
          {isInRolledOutInstitutions &&
            account.transactionSyncState === TransactionSyncState.PENDING && (
              <>
                <br />
                <Text
                  as={'bodyXs'}
                  style={{
                    fontStyle: 'italic',
                    color: Colors.orange,
                  }}
                >
                  Pending confirmation by user
                </Text>
              </>
            )}
        </GridRowColumn>
        {linkedManualAccount && (
          <GridRowColumn short>
            <Text as="bodyXs">
              <b>Manual Account Linked</b>
            </Text>
            <Text as="bodyXs">
              <b> Name:</b> {linkedManualAccount?.manualAccount?.name}
            </Text>
          </GridRowColumn>
        )}
        {noRecentPlaidTransactions && !isInactive && (
          <GridRowColumn short>
            <Text as="bodyXs" color="red">
              <b>Error:</b> Over 7 days since last transaction
            </Text>
            <Text as="bodyXs" color="red">
              <b>Date of last transaction:</b> {dateOfLastTransaction}
            </Text>
          </GridRowColumn>
        )}
      </Grid>
    </Card>
  )
}

export const AdminInstitutionCard = ({
  plaidItem,
  userId,
}: {
  plaidItem: AdminPlaidItem
  userId: number
}) => {
  const [noRecentTransactionsMap, setNoRecentTransactionsMap] = useState<{
    [id: number]: boolean
  }>({})
  const [datesOfLastTransaction, setDatesOfLastTransaction] = useState<{
    [id: number]: string
  }>({})
  const dispatch = useAppDispatch()
  const [deleteModalOpen, toggleDeleteModal] = useToggle()
  const [removeModalOpen, toggleRemoveModal] = useToggle()
  const [bankAccessStatusModalOpen, toggleBankAccessStatusModal] = useToggle()
  const [error, setError] = useState('')
  const [notifying, setNotifying] = useState(false)
  const [pulling, setPulling] = useState(false)
  const rolledOutInstitutionIds = useReselector(selectRolledOutInstitutionIds)
  const track = useAnalyticsTrack(true)

  const isInRolledOutInstitutions = checkIsInRolledOutInstitutions(
    rolledOutInstitutionIds,
    plaidItem.institutionId
  )

  const visibleAccounts = useReselector(
    selectAdminVisibleAccounts,
    userId,
    plaidItem.id,
    isInRolledOutInstitutions
  )

  const hiddenAccounts = useReselector(
    selectAdminHiddenAccounts,
    userId,
    plaidItem.id,
    isInRolledOutInstitutions
  )

  const fetchLastTransactionDates = useCallback(async () => {
    const allAccounts = [...(visibleAccounts ?? []), ...(hiddenAccounts ?? [])]
    const accountIds = allAccounts.map((account) => account.id)
    const newDates: { [id: number]: string } = {}
    const newRecents: { [id: number]: boolean } = {}
    for (const accountId of accountIds) {
      const res = await getDateOfLastTransaction(accountId)(dispatch)
      if (res) {
        const date = moment.utc(res.date).format(DATE_FORMATS.DISPLAY_SHORT)
        newDates[accountId] = date
        const hasNoRecent = moment(new Date()).diff(date, 'days') > 7
        newRecents[accountId] = hasNoRecent
      }
    }
    setDatesOfLastTransaction(newDates)
    setNoRecentTransactionsMap(newRecents)
  }, [visibleAccounts, hiddenAccounts, dispatch])

  useEffect(() => {
    fetchLastTransactionDates()
  }, [fetchLastTransactionDates])

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchRolledOutInstitutionIds(userId))
    }
    fetch()
  }, [dispatch, userId])

  const noRecentTransactions = useMemo(() => {
    return Object.values(noRecentTransactionsMap).includes(true)
  }, [noRecentTransactionsMap])

  const notifyUser = async () => {
    setNotifying(true)
    const data = {
      plaidItemId: plaidItem.id,
    }

    const res = await forceNotifyUserReconnection(data)(dispatch)
    if (!res) {
      setError('notify reconnect')
    }
    track('clicked plaid notify reconnect', {
      fi_name: plaidItem.institutionName,
    })
    setNotifying(false)
  }

  const pullTransactions = async () => {
    setPulling(true)
    const data = {
      plaidItemId: plaidItem.id,
    }
    const res = await forcePullTransactions(data)(dispatch)
    if (!res) {
      setError('force pull')
    }
    track('clicked plaid force pull', {
      fi_name: plaidItem.institutionName,
    })
    setPulling(false)
    if (typeof res !== 'boolean') {
      const numAddedTransactions = res.transactionIds.length
      if (numAddedTransactions > 0) {
        await fetchLastTransactionDates()
        // skipcq: JS-0052 - will be removed later for modal
        alert(
          `Force pull successful: There are ${numAddedTransactions} new transactions.`
        )
      } else {
        // skipcq: JS-0052 - will be removed later for modal
        alert('Force pull completed: No recent transactions.')
      }
    }
  }

  const displayUpdateBankAccess = useMemo(() => {
    if (!visibleAccounts) return false
    return !visibleAccounts.every(
      (account) => account.statementPermissions === 'plaid_statement'
    )
  }, [visibleAccounts])

  const { needsReconnection, institutionName, itemId } = plaidItem

  let className = 'accountCard'

  const isInactive = itemId === 'inactive'

  if (!isInactive && (needsReconnection || noRecentTransactions)) {
    className = `${className} needsReconnection`
  }

  return (
    <SemanticCard className={className} fluid>
      <SemanticCard.Content>
        <Grid>
          <GridRowColumn>
            <Label horizontal>plaid</Label>
            {isInactive ? (
              <Label horizontal>inactive</Label>
            ) : (
              <Label color={needsReconnection ? 'red' : undefined} horizontal>
                {needsReconnection ? 'Needs Reconnect' : 'connected'}
              </Label>
            )}
          </GridRowColumn>
          {noRecentTransactions && !isInactive && (
            <GridRowColumn short>
              <Text as="bodyXs" color="red">
                <b>No recent transactions</b>
              </Text>
            </GridRowColumn>
          )}
          <GridRowColumn short>
            <Text as="h2" color={isInactive ? 'darkGray' : undefined}>
              {institutionName}
            </Text>
            <Text as="bodyXs">Plaid Item ID: {plaidItem.itemId}</Text>
          </GridRowColumn>
          {visibleAccounts.map((account) => (
            <GridRowColumn key={account.id} short>
              <AdminAccountCard
                account={account}
                dateOfLastTransaction={datesOfLastTransaction[account.id]}
                noRecentPlaidTransactions={noRecentTransactionsMap[account.id]}
                needsReconnection={needsReconnection}
                userId={userId}
                isInactive={isInactive}
              />
            </GridRowColumn>
          ))}
          {isInRolledOutInstitutions && hiddenAccounts.length > 0 && (
            <GridRowColumn>
              <Accordion
                title={`View ${hiddenAccounts.length} hidden`}
                variant={'text'}
                content={hiddenAccounts.map((account) => (
                  <AdminAccountCard
                    key={account.id}
                    account={account}
                    dateOfLastTransaction={datesOfLastTransaction[account.id]}
                    noRecentPlaidTransactions={
                      noRecentTransactionsMap[account.id]
                    }
                    needsReconnection={needsReconnection}
                    userId={userId}
                    isInactive={isInactive}
                  />
                ))}
              />
            </GridRowColumn>
          )}
          <Divider />
          <GridRowColumn short>
            <Button
              fullWidth
              variant="warning"
              size="medium"
              onClick={toggleDeleteModal}
            >
              Delete Permanently
            </Button>
            {!isInactive && (
              <>
                <Divider />
                {displayUpdateBankAccess && (
                  <Button
                    fullWidth
                    variant="secondary"
                    size="medium"
                    style={{ marginBottom: 8 }}
                    onClick={toggleBankAccessStatusModal}
                  >
                    Update Limited Bank Access
                  </Button>
                )}
                <Button
                  fullWidth
                  variant="secondary"
                  size="medium"
                  style={{ marginBottom: 8 }}
                  onClick={toggleRemoveModal}
                >
                  Remove Connection
                </Button>
                <Button
                  fullWidth
                  variant="secondary"
                  size="medium"
                  style={{ marginBottom: 8 }}
                  loading={pulling}
                  onClick={pullTransactions}
                >
                  Force Pull
                </Button>
                <Button
                  fullWidth
                  variant="secondary"
                  size="medium"
                  loading={notifying}
                  disabled={!needsReconnection}
                  onClick={notifyUser}
                >
                  Notify Reconnect
                </Button>
              </>
            )}
          </GridRowColumn>
        </Grid>
      </SemanticCard.Content>
      <AdminFinancialErrorModal
        open={Boolean(error)}
        toggleOpen={() => setError('')}
      />
      <AdminDeleteModal
        open={deleteModalOpen}
        toggleOpen={toggleDeleteModal}
        plaidItem={plaidItem}
        userId={userId}
        setError={(errorType: string) => setError(errorType)}
      />
      <AdminRemoveModal
        open={removeModalOpen}
        toggleOpen={toggleRemoveModal}
        plaidItem={plaidItem}
        userId={userId}
        setError={(errorType: string) => setError(errorType)}
      />
      <AdminBankAccessStatusModal
        open={bankAccessStatusModalOpen}
        toggleOpen={toggleBankAccessStatusModal}
        accounts={visibleAccounts}
        plaidItem={plaidItem}
      />
    </SemanticCard>
  )
}
