import axios from 'axios'

import { updateCurrentUser, User } from '../reducers/auth/userReducer'
import { fetchIfNeededWrapper, fetchWrapper } from '../reducers/fetch'
import { UserTaxStatus } from '../reducers/admin/allUsersReducer'
import { receiveRolledOutInstitutionIds } from '../reducers/auth/rolledOutInstitutionsReducer'

axios.defaults.headers.common = {}
axios.defaults.headers.common.accept = 'application/json'

interface UserFetchPayload extends User {
  userTaxStatuses: UserTaxStatus[]
}

export const FETCH_CURRENT_USER_KEY = 'FETCH_CURRENT_USER_KEY'

export const fetchCurrentUserDetailsIfNeeded = (forceFetch?: boolean) =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_CURRENT_USER_KEY,
    defaultErrorMessage: 'Unable to retrieve current user details.',
    alwaysFetch: Boolean(forceFetch),
    fetchFunction: (dispatch) =>
      axios.get<UserFetchPayload>('/finances/api/v1/users').then((json) => {
        dispatch(updateCurrentUser(json.data))
      }),
  })

// Api expects numbers to be sent for dates
export interface UpdateUserPayload
  extends Omit<
    User,
    | 'payrollEnrollmentDeclinedAt'
    | 'payrollEnabledAt'
    | 'interestedInGepAt'
    | 'gepRequestFormSubmittedAt'
  > {
  payrollEnrollmentDeclinedAt: number | null
  payrollEnabledAt: number | null
  interestedInGepAt: number | null
  gepRequestFormSubmittedAt: number | null
  eoyReconciliationYear?: string | null
}

export const UPDATE_USER_KEY = 'UPDATE_USER_KEY'
export const updateUser = (
  id: number | undefined,
  data: Partial<UpdateUserPayload>
) =>
  fetchWrapper({
    fetchKey: UPDATE_USER_KEY,
    defaultErrorMessage: 'Unable to update user.',
    fetchFunction: (dispatch) => {
      if (!id) {
        return undefined
      }

      return axios
        .post<User>(`/finances/api/v1/users/${id}`, data)
        .then((json) => {
          dispatch(updateCurrentUser(json.data))
          return json.data
        })
    },
  })

export const FETCH_ROLLED_OUT_INSTITUTION_IDS =
  'FETCH_ROLLED_OUT_INSTITUTION_IDS'
export const fetchRolledOutInstitutionIds = (
  userId: number | undefined,
  alwaysFetch = true
) =>
  fetchIfNeededWrapper({
    defaultErrorMessage:
      "There was an error fetching user's rollout group status.",
    alwaysFetch,
    fetchKey: FETCH_ROLLED_OUT_INSTITUTION_IDS,
    fetchFunction: (dispatch) =>
      axios
        .get<{
          rolledOutInstitutionIds: string[]
        }>(`/finances/api/v1/accounts/rolled-out-institution-ids/${userId}`)
        .then((json) => {
          dispatch(
            receiveRolledOutInstitutionIds(json.data.rolledOutInstitutionIds)
          )
          return json.data
        }),
  })

export const DOWNLOAD_ACCOUNT_ZIP_KEY = 'DOWNLOAD_ACCOUNT_ZIP_KEY'
export const downloadAccountInformation = (id: number | undefined) =>
  fetchWrapper({
    fetchKey: UPDATE_USER_KEY,
    defaultErrorMessage: 'Unable to update user.',
    fetchFunction: () => {
      return axios
        .get<undefined>(
          `/finances/api/v1/users/${id}/download_account_information`
        )
        .then(() => {
          return true
        })
    },
  })

export const UPDATE_USE_MEMBERSHIP_KEY = 'UPDATE_USE_MEMBERSHIP_KEY'
export const updateMembership = (
  id: number | undefined,
  data: { manualCancellationRequestedAt: string | null }
) =>
  fetchWrapper({
    fetchKey: UPDATE_USE_MEMBERSHIP_KEY,
    defaultErrorMessage: 'Unable to update user membership.',
    fetchFunction: (dispatch) => {
      if (!id) {
        return undefined
      }

      return axios
        .post<User>(`/finances/api/v1/users/${id}/update_membership`, data)
        .then((json) => {
          dispatch(updateCurrentUser(json.data))
          return json.data
        })
    },
  })

export const ACTIVATE_CANCELLED_ACCOUNT = 'ACTIVATE_CANCELLED_ACCOUNT'
export const activateCancelledAccount = (id: number | undefined) =>
  fetchWrapper({
    fetchKey: ACTIVATE_CANCELLED_ACCOUNT,
    defaultErrorMessage: 'Unable to reactivate cancelled account.',
    fetchFunction: (dispatch) => {
      if (!id) {
        return undefined
      }

      return axios
        .post<User>(`/finances/api/v1/users/${id}/activate_canceled_account`)
        .then((json) => {
          dispatch(updateCurrentUser(json.data))
          return json.data
        })
    },
  })
